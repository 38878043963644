<template>
  <nav aria-label="breadcrumb" class="alert alert-primary rounded-4 pt-1 pb-1">
    <ol class="breadcrumb m-0" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </router-link>
        <meta itemprop="position" content="1">
      </li>
      <li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <span itemprop="name">Подписки</span>
        <meta itemprop="position" content="2">
      </li>
    </ol>
  </nav>

  <div class="row">
    <div :class="[
        !authStore.isAuthenticated() ? 'col-12' : 'col-6',
        authStore.isAuthenticated() && 'col-12 col-md-6 mb-md-0 mb-4'
      ]">
      <div class="card rounded-4">
        <div class="card-body">
          <h1 class="mb-0 fs-4 text-center">Подписки на инструменты</h1>
        </div>
        <div class="card-footer pt-3 pb-3" v-if="!authStore.isAuthenticated()">
          <div class="row">
            <div class="col-12 col-lg-4 col-sm-6">
              <div class="card rounded-4 position-relative">
                <router-link class="text-decoration-none" to="/subscriptions/v1/keywords">
                  <div class="card-body d-flex flex-column align-items-center pb-2">
                    <img class="mb-2" src="../../assets/icons/1f5d2.svg" alt="">
                    <h2 class="fs-6 mb-2 text-center">Поиск ключевых слов</h2>
                    <span class="badge w-50">Версия 1.0</span>
                  </div>
                  <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: green">Покупка доступна</span>
                </router-link>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-sm-6 mt-sm-0 mt-4">
              <div class="card rounded-4 position-relative">
                <div class="card-body d-flex flex-column align-items-center pb-2">
                  <img class="mb-2" src="../../assets/icons/1f525.svg" alt="">
                  <h2 class="fs-6 mb-2 text-center">Трендовые видео</h2>
                  <span class="badge w-50">Версия 1.0</span>
                </div>
                <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Покупка недоступна</span>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-sm-6 mt-lg-0 mt-4">
              <div class="card rounded-4 position-relative">
                <div class="card-body d-flex flex-column align-items-center pb-2">
                  <img class="mb-2" src="../../assets/icons/1f642.svg" alt="">
                  <h2 class="fs-6 mb-2 text-center">Трендовые Shorts</h2>
                  <span class="badge w-50">Версия 1.0</span>
                </div>
                <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Покупка недоступна</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer pt-3 pb-3" v-else>
          <div class="row">
            <div class="col-12">
              <div class="card rounded-4 position-relative">
                <router-link class="text-decoration-none" to="/subscriptions/v1/keywords">
                  <div class="card-body d-flex flex-column align-items-center pb-2">
                    <img class="mb-2" src="../../assets/icons/1f5d2.svg" alt="">
                    <h2 class="fs-6 mb-2 text-center">Поиск ключевых слов</h2>
                    <span class="badge w-50">Версия 1.0</span>
                  </div>
                  <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: green">Покупка доступна</span>
                </router-link>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="card rounded-4 position-relative">
                <div class="card-body d-flex flex-column align-items-center pb-2">
                  <img class="mb-2" src="../../assets/icons/1f525.svg" alt="">
                  <h2 class="fs-6 mb-2 text-center">Трендовые видео</h2>
                  <span class="badge w-50">Версия 1.0</span>
                </div>
                <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Покупка недоступна</span>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="card rounded-4 position-relative">
                <div class="card-body d-flex flex-column align-items-center pb-2">
                  <img class="mb-2" src="../../assets/icons/1f642.svg" alt="">
                  <h2 class="fs-6 mb-2 text-center">Трендовые Shorts</h2>
                  <span class="badge w-50">Версия 1.0</span>
                </div>
                <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Покупка недоступна</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="authStore.isAuthenticated() ? 'col-12 col-md-6' : 'col-12'" v-if="authStore.isAuthenticated()">
      <div class="card rounded-4">
        <div class="card-body">
          <h1 class="mb-0 fs-4 text-center">Мои активные подписки</h1>
        </div>
        <div class="card-footer pt-3 pb-3">
          <div class="row justify-content-center align-items-center">
            <div v-if="subscriptions.length === 0" class="col-12">
              <p class="text-center mb-0">Активных подписок нет</p>
            </div>

            <div v-else-if="hasKeywordSubscription" class="col-12">
              <div class="card rounded-4 position-relative">
                <div class="card-body d-flex flex-column align-items-center pb-2">
                  <img class="mb-2" src="../../assets/icons/1f5d2.svg" alt="">
                  <h2 class="fs-6 mb-2 text-center">Поиск ключевых слов</h2>
                  <span class="badge w-50">Версия 1.0</span>
                </div>
                <div class="d-flex align-items-center badge rounded-4 text-center position-absolute top-0 end-0 translate-middle-y me-2 ps-3 pe-3" style="background-color: green">
                  <font-awesome-icon icon="clock" class="me-1" />
                  <span>{{ formattedRemainingTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';
import dayjs from 'dayjs';

const store = useAuthStore();
const authStore = useAuthStore();
const subscriptions = computed(() => store.userSubscriptions);

const hasKeywordSubscription = computed(() => {
  return subscriptions.value.some(sub => sub.plan_id === 500);
});

const remainingTime = ref({
  days: 0,
  hours: 0,
  minutes: 0
});

const dayWord = (days) => {
  const lastDigit = days % 10;
  const lastTwoDigits = days % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return 'дней';
  if (lastDigit === 1) return 'день';
  if (lastDigit >= 2 && lastDigit <= 4) return 'дня';
  return 'дней';
};

const hourWord = (hours) => {
  const lastDigit = hours % 10;
  const lastTwoDigits = hours % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return 'часов';
  if (lastDigit === 1) return 'час';
  if (lastDigit >= 2 && lastDigit <= 4) return 'часа';
  return 'часов';
};

const minuteWord = (minutes) => {
  const lastDigit = minutes % 10;
  const lastTwoDigits = minutes % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return 'минут';
  if (lastDigit === 1) return 'минута';
  if (lastDigit >= 2 && lastDigit <= 4) return 'минуты';
  return 'минут';
};

const updateRemainingTime = () => {
  const subscription = subscriptions.value.find(sub => sub.plan_id === 500);
  if (subscription) {
    const now = dayjs();
    const toDate = dayjs.unix(subscription.to);
    const diff = toDate.diff(now);

    if (diff > 0) {
      remainingTime.value = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / (1000 * 60)) % 60),
      };
    } else {
      remainingTime.value = { days: 0, hours: 0, minutes: 0 };
    }
  }
};

const formattedRemainingTime = computed(() => {
  const { days, hours, minutes } = remainingTime.value;

  const parts = [];

  if (days > 0) parts.push(`${days} ${dayWord(days)}`);
  if (hours > 0) parts.push(`${hours} ${hourWord(hours)}`);
  if (minutes > 0) parts.push(`${minutes} ${minuteWord(minutes)}`);

  return parts.join(', ');
});

let intervalId;

onMounted(() => {
  updateRemainingTime();
  intervalId = setInterval(updateRemainingTime, 6000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<style scoped>
img {
  width: 55px;
}

.card {
  color: var(--text-color);
}
</style>