<template>
  <div class="card rounded-4">
    <div class="card-body">
      <div class="row">
        <div class="col-3 d-flex flex-column justify-content-between">
          <ul class="nav nav-tabs nav-pills nav-fill flex-column" id="authTabs" role="tablist">
            <li class="nav-item mb-3" role="presentation">
              <router-link class="nav-link rounded-4" to="/me" :class="{ 'active': isActiveRoute('/me') }">
                Мой профиль
              </router-link>
            </li>
            <li class="nav-item mb-3" role="presentation">
              <router-link class="nav-link rounded-4" to="/me/subscriptions" :class="{ 'active': isActiveRoute('/me/subscriptions') }">
                Мои подписки
              </router-link>
            </li>
            <li class="nav-item" role="presentation">
              <router-link class="nav-link rounded-4" to="/me/settings" :class="{ 'active': isActiveRoute('/me/settings') }">
                Настройки
              </router-link>
            </li>
          </ul>
          <button class="btn btn-link text-decoration-none text-danger">Удалить аккаунт</button>
        </div>
        <div class="col-9">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const isActiveRoute = (path) => {
  return route.path === path;
};
</script>

<style scoped>

</style>