<template>
  <div class="dropdown me-3" :class="{ show: isDropdownOpen }">
    <button
        class="btn btn-outline-primary dropdown-toggle rounded-4"
        type="button"
        @click="toggleDropdown"
        :aria-expanded="isDropdownOpen"
        :aria-label="selectedOption"
        :disabled="store.loading"
    >
      {{ selectedOption }}
    </button>
    <ul class="dropdown-menu" :class="{ show: isDropdownOpen }" style="z-index: 9999999;" role="menu">
      <li
          v-for="option in options"
          :key="option"
          class="dropdown-item"
          role="menuitem"
          @click="selectOption(option)"
      >
      {{ option }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';

const options = [
  'За этот месяц',
  'За эту неделю',
  'За Сегодня'
];

const periodMapping = {
  'За этот месяц': 'month',
  'За эту неделю': 'week',
  'За Сегодня': 'day'
};

const selectedOption = ref(options[0]);
const isDropdownOpen = ref(false);
const store = useKeywordsStore();

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectOption = (option) => {
  if (selectedOption.value === option) {
    isDropdownOpen.value = false;
    return;
  }

  selectedOption.value = option;
  const selectedPeriod = periodMapping[option];
  store.setPeriod(selectedPeriod);
  isDropdownOpen.value = false;
};
</script>