<template>
  <div class="table-responsive-inner">
    <div v-if="filteredKeywords.length === 0" class="d-flex flex-column justify-content-center align-items-center" style="height: 300px;">
      <h1 class="text-muted fs-4 mb-0">Ничего не нашлось</h1>
      <p class="mb-0 text-muted">попробуйте использовать другие фильтры</p>
    </div>
    <table class="table mb-0 table-dark" v-else>
      <thead class="sticky-top">
      <tr v-if="store.loading">
        <th class="nowrap ps-3">
          <div class="skeleton rounded-4" style="width: 190px; height: 24px;"></div>
        </th>
        <th class="nowrap text-center">
          <div class="skeleton rounded-4" style="width: 100px; height: 24px;"></div>
        </th>
        <th class="nowrap text-center">
          <div class="skeleton rounded-4" style="width: 170px; height: 24px;"></div>
        </th>
        <th class="nowrap text-center">
          <div class="skeleton rounded-4" style="width: 120px; height: 24px;"></div>
        </th>
        <th class="nowrap text-center">
          <div class="skeleton rounded-4" style="width: 130px; height: 24px;"></div>
        </th>
      </tr>
      <tr v-else>
        <th class="sticky-left nowrap ps-3">Ключ. слово</th>
        <th class="nowrap text-center">Похожесть</th>
        <th class="nowrap text-center">Объем показов</th>
        <th class="nowrap text-center">Конкуренция</th>
        <th class="nowrap text-center">Общая оценка</th>
      </tr>
      </thead>
      <tbody v-if="store.loading">
      <tr v-for="n in 30" :key="n" class="keywords-list">
        <td class="nowrap">
          <div class="d-flex align-items-center">
            <div class="skeleton rounded-4" style="width: 190px; height: 24px;"></div>
          </div>
        </td>
        <td class="text-center align-middle">
          <div class="d-flex align-items-center justify-content-center">
            <div class="skeleton rounded-4" style="width: 90px; height: 24px;"></div>
          </div>
        </td>
        <td class="text-center align-middle">
          <div class="d-flex align-items-center justify-content-center">
            <div class="skeleton rounded-4" style="width: 130px; height: 24px;"></div>
          </div>
        </td>
        <td class="nowrap text-center align-middle">
          <div class="d-flex align-items-center justify-content-center">
            <div class="skeleton rounded-4" style="width: 130px; height: 30px;"></div>
          </div>
        </td>
        <td class="nowrap text-center align-middle">
          <div class="d-flex align-items-center justify-content-center">
            <div class="skeleton rounded-4" style="width: 130px; height: 30px;"></div>
          </div>
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr v-for="(keyword, index) in filteredKeywords" :key="index" class="keywords-list">
        <td class="sticky-left nowrap text-muted">
          <div class="row">
            <div class="col-12 d-flex align-items-center ps-3">
              <input type="checkbox" :value="keyword.keyword" v-model="selectedKeywords" class="me-3 h-100" />
              <button class="me-3 ps-0 pe-0 btn" @click="copyToClipboard(keyword.keyword)">
                <font-awesome-icon icon="copy" />
              </button>
              <div
                  style="max-width: 200px;">
                {{ keyword.keyword }}
              </div>
            </div>
          </div>
        </td>
        <td class="text-center align-middle">{{ formatNumber(keyword.related_score) }}</td>
        <td class="text-center align-middle">{{ formatNumber(keyword.estimated_monthly_search) }}</td>
        <td class="nowrap text-center align-middle">
          <div class="badge-competition" :class="getCompetitionClass(keyword.competition)">
            {{ getCompetitionText(keyword.competition) }}
          </div>
        </td>
        <td class="nowrap text-center align-middle">
          <div class="badge-score" :class="getTotalScoreClass(keyword.overall)">
            {{ getTotalScoreText(keyword.overall) }}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="sticky-bottom d-flex justify-content-center" v-if="selectedKeywords.length > 0">
      <button class="btn btn-outline-primary rounded-4" @click="copySelectedKeywords">
        Скопировать ключевые слова
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';
import { formatNumber } from '@/utils/formatNumber';
import { useToast } from "vue-toastification";

const store = useKeywordsStore();
const toast = useToast();

const selectedKeywords = ref([]);

// Копирование выбранных ключевых слов через запятую
const copySelectedKeywords = async () => {
  const keywords = selectedKeywords.value.join(',');
  if (!navigator.clipboard) {
    toast.error('Копирование не поддерживается в этом браузере');
    return;
  }

  try {
    await navigator.clipboard.writeText(keywords);
    toast.info('Ключевые слова скопированы');
  } catch (error) {
    toast.error(`Ошибка копирования: ${error.message}`);
  }
};

const competitionLevels = [
  { threshold: 80, text: 'очень высокая', class: 'high' },
  { threshold: 60, text: 'высокая', class: 'moderate' },
  { threshold: 40, text: 'средняя', class: 'medium' },
  { threshold: 20, text: 'низкая', class: 'low' },
  { threshold: 0, text: 'очень низкая', class: 'very-low' }
];

const scoreLevels = [
  { threshold: 80, text: 'очень высокий', class: 'very-low' },
  { threshold: 60, text: 'высокий', class: 'low' },
  { threshold: 40, text: 'средний', class: 'medium' },
  { threshold: 20, text: 'низкий', class: 'moderate' },
  { threshold: 0, text: 'очень низкий', class: 'high' }
];

const getLevelData = (value, levels) => {
  return levels.find(level => value >= level.threshold);
};

const getCompetitionText = (competition) => {
  return getLevelData(competition, competitionLevels).text;
};

const getCompetitionClass = (competition) => {
  return getLevelData(competition, competitionLevels).class;
};

const getTotalScoreText = (score) => {
  return getLevelData(score, scoreLevels).text;
};

const getTotalScoreClass = (score) => {
  return getLevelData(score, scoreLevels).class;
};

const copyToClipboard = async (keyword) => {
  if (!navigator.clipboard) {
    toast.error('Копирование не поддерживается в этом браузере');
    return;
  }

  try {
    await navigator.clipboard.writeText(keyword);
    toast.info('Ключевое слово скопировано');
  } catch (error) {
    toast.error(`Ошибка копирования: ${error.message}`);
  }
};

const filteredKeywords = computed(() => {
  if (!store.exampleKeywordsSearchResult) return [];

  return store.exampleKeywordsSearchResult.related.filter(keyword => {
    const competitionClass = getCompetitionClass(keyword.competition); // 'very-low', 'low', и т.д.
    const overallClass = getTotalScoreClass(keyword.overall);          // 'very-high', 'high', и т.д.

    const competitionFilter = store.selectedCompetition;
    const overallFilter = store.selectedOverallScore;

    const competitionMatch = competitionFilter === 'all' || competitionClass === competitionFilter;
    const overallMatch = overallFilter === 'all' || overallClass === overallFilter;

    // Новые фильтры
    const searchVolumeFrom = store.searchVolumeFrom;
    const searchVolumeTo = store.searchVolumeTo;
    const searchVolume = keyword.estimated_monthly_search; // Предполагается, что это имя свойства объема поиска

    const searchVolumeMatch =
        (searchVolumeFrom === null || searchVolume >= searchVolumeFrom) &&
        (searchVolumeTo === null || searchVolume <= searchVolumeTo);

    return competitionMatch && overallMatch && searchVolumeMatch;
  });
});
</script>

<style scoped>
.badge-competition, .badge-score {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  font-size: 13px;
  min-width: 100px;
}

.table td {
  vertical-align: middle;
}

.high {
  background-color: #ff0000;
  color: white;
}

.moderate {
  background-color: #ff4e4e;
  color: white;
}

.medium {
  background-color: #ff7a00;
  color: white;
}

.low {
  background-color: #008000;
  color: white;
}

.very-low {
  background-color: #005d00;
  color: white;
}

.btn:first-child:active {
  border-color: #ffffff00;
}

svg {
  color: var(--brend-color);
}

svg:hover {
  color: var(--text-color);
}

.sticky-bottom {
  padding: 10px 10px 0;
  background: var(--header-bg);
}

/* Закрепляем шапку таблицы */
.table thead th {
  position: sticky;
  top: 0;
  z-index: 3;
  white-space: nowrap; /* Запрещаем перенос в шапке */
}

/* Предотвращаем сжатие остальных колонок */
.table th,
.table td {
  white-space: nowrap; /* Не переносим текст в ячейках по умолчанию */
  min-width: 150px; /* Минимальная ширина колонок */
}

/* Первая колонка (ключевое слово) с переносом текста и фиксированной шириной */
.table th.sticky-left,
.table td.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
  max-width: 200px; /* Ограничиваем ширину колонки */
  white-space: normal; /* Включаем перенос текста для первой колонки */
  word-wrap: break-word; /* Разрешаем перенос длинных слов */
}

/* Заголовок первой колонки должен быть выше по z-index */
.table th.sticky-left {
  z-index: 4;
}

/* Поддерживаем стики заголовков и первой колонки одновременно */
.table thead th.sticky-left {
  z-index: 5; /* Самый высокий уровень для заголовка первой колонки */
}
</style>