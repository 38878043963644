<template>
  <div class="dropdown" :class="{ show: isDropdownOpen }">
    <button
        class="btn btn-outline-primary dropdown-toggle rounded-4"
        type="button"
        @click="toggleDropdown"
        :aria-expanded="isDropdownOpen"
        :aria-label="selectedLanguage"
        :disabled="store.loading"
    >
      {{ selectedLanguageText }}
    </button>
    <ul class="dropdown-menu" :class="{ show: isDropdownOpen }" style="z-index: 9999999;" role="menu">
      <li
          v-for="(language, code) in languages"
          :key="code"
          class="dropdown-item"
          role="menuitem"
          @click="selectLanguage(code)"
      >
      {{ language }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';

const store = useKeywordsStore();
const languages = {
  all: 'На всех языках',
  en: 'На Английском',
  ru: 'На Русском',
  es: 'На Испанском',
  pt: 'На Португальском',
};

const selectedLanguage = ref('all');
const isDropdownOpen = ref(false);

const selectedLanguageText = computed(() => languages[selectedLanguage.value]);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectLanguage = (code) => {
  if (selectedLanguage.value === code) {
    isDropdownOpen.value = false;
    return;
  }

  selectedLanguage.value = code;
  store.setLanguage(code);
  isDropdownOpen.value = false;
};
</script>