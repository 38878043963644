import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router/router';
import Toast from 'vue-toastification';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@/assets/style/main.css';
import 'vue-toastification/dist/index.css';
import '../node_modules/nprogress/nprogress.css';
import {
    faCopy,
    faAngleDown,
    faClock,
    faPuzzlePiece,
    faTools,
    faBell,
    faRightToBracket,
    faArrowUp,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add({
    faCopy,
    faAngleDown,
    faClock,
    faPuzzlePiece,
    faTools,
    faBell,
    faRightToBracket,
    faArrowUp,
    faCheckCircle
})

const app = createApp(App);
const pinia = createPinia();

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(pinia);
app.use(router);
app.use(Toast);
app.mount('#app');
