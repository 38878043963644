<template>
  <div class="row">
    <div class="col-6">
      <div class="card rounded-4">
        <div class="card-header">
          <h2 class="fs-6 mb-0 text-center">Обновить пароль</h2>
        </div>
        <div class="card-body">
          <form>
            <input type="password" class="form-control rounded-4 mb-2" placeholder="Действующий пароль" required>
            <input type="password" class="form-control rounded-4 mb-2" placeholder="Новый пароль" required>
            <input type="password" class="form-control rounded-4 mb-3" placeholder="Новый пароль еще раз" required>
            <button type="submit" class="btn btn-outline-primary rounded-4 w-100">Отправить код на почту</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card rounded-4">
        <div class="card-header">
          <h2 class="fs-6 mb-0 text-center">Обновить почту</h2>
        </div>
        <div class="card-body">
          <form>
            <input type="password" class="form-control rounded-4 mb-2" :placeholder="user.email" disabled>
            <input type="password" class="form-control rounded-4 mb-3" placeholder="Новая почта" required>
            <button type="submit" class="btn btn-outline-primary rounded-4 w-100">Отправить код на почту</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/useAuthStore";
import { computed } from "vue";

const authStore = useAuthStore();
const user = computed(() => authStore.user);

</script>

<style scoped>
.card {
  color: var(--text-color);
}
</style>