<template>
  <div class="card-footer">
    <div v-if="authStore.isAuthenticated()">
      <form @submit.prevent="handleSearch">
        <input
            v-model="keyword"
            class="form-control form-control-lg rounded-4"
            type="text"
            placeholder="Введите ключевое слово"
            required
            :disabled="store.loading"
        >
      </form>
    </div>
    <div class="alert alert-primary mb-0 rounded-4 text-center" role="alert" v-if="!authStore.isAuthenticated()">
      Зарегистрируйтесь или войдите в свой аккаунт чтобы использовать инструмент.
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';
import { useAuthStore } from '@/stores/useAuthStore';

const store = useKeywordsStore();
const authStore = useAuthStore();


const keyword = computed({
  get() {
    return store.currentKeyword;
  },
  set(value) {
    store.currentKeyword = value;
  }
});

const handleSearch = async () => {
  if (authStore.isAuthenticated()) {
    if (authStore.hasActive500Plan) {
      await store.searchKeywords(store.currentKeyword);
    } else {
      await store.searchKeyword(store.currentKeyword);
    }
  }
};
</script>
