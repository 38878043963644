import {getTop100Keywords, keywordExample, searchKeyword, searchKeywords} from "@/api/tools/v1/keywords/Keywords";
import { defineStore } from "pinia";
import { useAuthStore } from '@/stores/useAuthStore';

export const useKeywordsStore = defineStore('keywords', {
    state: () => ({
        error: null,
        loading: false,
        top100Keywords: [],
        keywordsSearchResult: null,
        keywordSearchResult: null,
        exampleKeywordsSearchResult: null,
        selectedPeriod: 'month',
        selectedLanguage: 'all',
        selectedCompetition: 'all',
        selectedOverallScore: 'all',
        searchVolumeFrom: null,
        searchVolumeTo: null,
        currentKeyword: ''
    }),
    actions: {
        async getTop100Keywords() {
            this.loading = true;
            try {
                const data = await getTop100Keywords(this.selectedPeriod, this.selectedLanguage);
                this.error = null;
                this.top100Keywords = data.keywords;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка';
            } finally {
                this.loading = false;
            }
        },

        async searchKeywords(keyword) {
            this.loading = true;
            const authStore = useAuthStore();
            const token = authStore.token;

            if (!token) {
                this.error = 'Токен авторизации отсутствует';
                this.loading = false;
                return;
            }

            try {
                this.currentKeyword = keyword; // Сохраняем ключевое слово
                const data = await searchKeywords(token, keyword);
                this.error = null;
                this.keywordsSearchResult = data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при поиске ключевого слова';
            } finally {
                this.loading = false;
            }
        },

        async searchKeyword(keyword) {
            this.loading = true;
            const authStore = useAuthStore();
            const token = authStore.token;

            if (!token) {
                this.error = 'Токен авторизации отсутствует';
                this.loading = false;
                return;
            }

            try {
                this.currentKeyword = keyword; // Сохраняем ключевое слово
                const data = await searchKeyword(token, keyword);
                this.error = null;
                this.keywordSearchResult = data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при поиске ключевого слова';
            } finally {
                this.loading = false;
            }
        },

        async exampleSearchKeywords() {
            this.loading = true;

            try {
                const data = await keywordExample();
                this.error = null;
                this.exampleKeywordsSearchResult = data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при поиске ключевого слова';
            } finally {
                this.loading = false;
            }
        },

        async setPeriod(period) {
            this.selectedPeriod = period;
            await this.getTop100Keywords(); // Обновляем данные при изменении периода с использованием await
        },

        async setLanguage(language) {
            this.selectedLanguage = language;
            await this.getTop100Keywords(); // Обновляем данные при изменении языка с использованием await
        },

        setSelectedCompetition(filter) {
            this.selectedCompetition = filter;
        },

        setSelectedOverallScore(filter) {
            this.selectedOverallScore = filter;
        },

        setSearchVolumeFrom(value) {
            this.searchVolumeFrom = value;
        },

        setSearchVolumeTo(value) {
            this.searchVolumeTo = value;
        }
    }
});
