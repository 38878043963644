<template>
  <nav aria-label="breadcrumb" class="alert alert-primary rounded-4 pt-1 pb-1">
    <ol class="breadcrumb m-0" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </router-link>
        <meta itemprop="position" content="1">
      </li>
      <li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <span itemprop="name">Инструменты</span>
        <meta itemprop="position" content="2">
      </li>
    </ol>
  </nav>

  <div class="card rounded-4">
    <div class="card-body">
      <h1 class="mb-0 fs-4 text-center">Инструменты сервиса</h1>
    </div>
    <div class="card-footer pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-lg-4 col-sm-6">
          <div class="card rounded-4 position-relative">
            <router-link class="text-decoration-none" to="/tools/v1/keywords">
              <div class="card-body d-flex flex-column align-items-center pb-2">
                <img class="mb-2" src="../../assets/icons/1f5d2.svg" alt="">
                <h2 class="fs-6 mb-2 text-center">Поиск ключевых слов</h2>
                <span class="badge w-50">Версия 1.0</span>
              </div>
            </router-link>
            <div class="card-footer">
              <p class="mb-0 card-text">
                Инструмент поиска и анализа ключевых слов.
              </p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: green">Работает</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-sm-0 mt-4">
          <div class="card rounded-4 position-relative">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f525.svg" alt="">
              <h2 class="fs-6 mb-2 text-center">Трендовые видео</h2>
              <span class="badge w-50">Версия 1.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">
                Long-видео, набирающие очень много просмотров.
              </p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Профилактика</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-lg-0 mt-4">
          <div class="card rounded-4 position-relative">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f642.svg" alt="">
              <h2 class="fs-6 mb-2 text-center">Трендовые Shorts</h2>
              <span class="badge w-50">Версия 1.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">
                Shorts-видео, набирающие очень много просмотров.
              </p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: #f4900c">Профилактика</span>
          </div>
        </div>

        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f5d2.svg" alt="">
              <h2 class="fs-6 text-center mb-2">Поиск ключевых слов</h2>
              <span class="badge w-50">Версия 2.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">Аналитика ключевых слов будет еще более точной!</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f525.svg" alt="">
              <h2 class="fs-6 mb-0 text-center mb-2">Трендовые видео</h2>
              <span class="badge w-50">Версия 2.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">Еще больше фильтров, еще больше база!</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f642.svg" alt="">
              <h2 class="fs-6 mb-0 text-center mb-2">Трендовые Shorts</h2>
              <span class="badge w-50">Версия 2.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">Еще больше фильтров, еще больше база!</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f4ca.svg" alt="">
              <h2 class="fs-6 mb-0 text-center mb-2">Конкурентный анализ</h2>
              <span class="badge w-50">Версия 1.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">Соберите и наблюдайте за вашими конкурентами 24/7</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2">
              <img class="mb-2" src="../../assets/icons/1f4c8.svg" alt="">
              <h2 class="fs-6 mb-0 text-center mb-2">Аналитика канала</h2>
              <span class="badge w-50">Версия 1.0</span>
            </div>
            <div class="card-footer">
              <p class="mb-0 card-text">Такая же аналитика как в творческой студии YouTube!</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-sm-6 mt-4">
          <div class="card rounded-4">
            <div class="card-body d-flex flex-column align-items-center pb-2" style="opacity: 0.3;">
              <img class="mb-2" src="../../assets/icons/1f4c9.svg" alt="">
              <h2 class="fs-6 mb-0 text-center mb-2">Аналитика видео</h2>
              <span class="badge w-50">Версия 1.0</span>
            </div>
            <div class="card-footer" style="opacity: 0.3;">
              <p class="mb-0 card-text">Такая же аналитика как в творческой студии YouTube!</p>
            </div>
            <span class="tool-status position-absolute badge ms-0" style="right: 15px; top: -8px; background-color: red">В разработке</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <p class="card-text">
        В нашем сервисе все инструменты делятся на версии.
        Самая первая версия инструмента – это «Beta». Она сырая, и может содержать ошибки,
        которые наша команда быстро исправляет. После этого инструмент переходит
        в версию «1.0» – она уже без ошибок и работает стабильно. Так же, в скором времени,
        будут добавлены варсии инструментов «2.0». Инструменты этой версии значительно
        отличаются по функционалу от версии «1.0».
      </p>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 55px;
}

.card {
  color: var(--text-color);
}
</style>