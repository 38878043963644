<template>
  <header-component />
  <div class="container user-select-none flex-grow-1" :style="{ paddingTop: showMenu ? '80px' : '120px', paddingBottom: showMenu ? '50px' : '30px' }">
    <router-view />
  </div>
  <cookie-consent />
  <footer-component />
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

const showMenu = ref(true);

const checkWindowWidth = () => {
  showMenu.value = window.innerWidth > 767;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkWindowWidth);
});
</script>
