<template>
  <div class="row justify-content-center align-items-center" style="height: 70vh;">
    <div class="col-12 col-md-7">
      <div class="card rounded-4">
        <div class="card-header">
          <ul class="nav nav-tabs nav-pills nav-fill" id="authTabs" role="tablist">
            <li class="nav-item me-0 me-sm-3 mb-2" role="presentation">
              <button class="nav-link rounded-4" :class="{ active: activeTab === 'login' }" @click="switchTab('login')" id="login-tab" type="button" role="tab" aria-controls="login" :aria-selected="activeTab === 'login'">Авторизация</button>
            </li>
            <li class="nav-item mb-2" role="presentation">
              <button class="nav-link rounded-4" :class="{ active: activeTab === 'registration' }" @click="switchTab('registration')" id="registration-tab" type="button" role="tab" aria-controls="registration" :aria-selected="activeTab === 'registration'">Регистрация</button>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'login' }" id="login" role="tabpanel" aria-labelledby="login-tab">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-6 col-md-9">
                  <form @submit.prevent="handleLogin">
                    <input v-model="email" type="email" class="form-control rounded-4 mb-2" placeholder="Почта" required>
                    <input v-model="password" type="password" class="form-control rounded-4 mb-3" placeholder="Пароль" required>
                    <button :disabled="loading" type="submit" class="btn btn-outline-primary rounded-4 w-100">
                      <span v-if="loading">Вхожу...</span>
                      <span v-else>Авторизация</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'registration' }" id="registration" role="tabpanel" aria-labelledby="registration-tab">
              <div class="row justify-content-center">
                <div class="col-12 col-md-9">
                  <form @submit.prevent="handleRegister">
                    <input v-model="name" type="text" class="form-control rounded-4 mb-2" placeholder="Ваше имя" required>
                    <input v-model="email" type="email" class="form-control rounded-4 mb-2" placeholder="Ваша почта" required>
                    <input v-model="password" type="password" class="form-control rounded-4 mb-2" placeholder="Придумайте хороший пароль" required>
                    <input v-model="confirmPassword" type="password" class="form-control rounded-4 mb-3" placeholder="Повторите пароль" required>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                    <div class="form-check form-switch mb-3" style="color: var(--text-color)">
                      <input v-model="agreedToTerms" class="form-check-input" type="checkbox" role="switch" id="confirm" required>
                      <label class="form-check-label" for="confirm">Я ознакомлен(а) и принимаю условия
                        <router-link to="/offer" class="text-decoration-none fs-6">
                          <span>Публичной оферты</span>
                        </router-link> и
                        <router-link to="/confidential" class="text-decoration-none fs-6">
                          <span>Политики конфиденциальности</span>
                        </router-link>
                      </label>
                    </div>
                    <button :disabled="!agreedToTerms || loading" type="submit" class="btn btn-outline-primary rounded-4 w-100">
                      <span v-if="loading">Регистрирую...</span>
                      <span v-else>Регистрация</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'reset' }" id="reset" role="tabpanel" aria-labelledby="reset-tab">
              <div class="row justify-content-center">
                <div v-if="resetSuccess" class="alert alert-primary mb-3 rounded-4">
                  Ссылка для сброса пароля отправлена на вашу почту. Если вы не видите сообщения во
                  входящих, проверьте папку "Спам". Перейдите по ссылке в письме, чтобы установить
                  новый пароль.
                </div>
                <div v-if="resetError" class="alert alert-danger rounded-4 mb-3">
                  {{ resetError }}
                </div>
                <div class="col-12 col-md-9">
                  <form @submit.prevent="handlePasswordReset">
                    <input v-model="resetEmail" type="email" class="form-control rounded-4 mb-2" placeholder="Введите почту" required>
                    <button :disabled="loading" type="submit" class="btn btn-outline-primary rounded-4 w-100">
                      <span v-if="loading">Отправляю...</span>
                      <span v-else>Сбросить пароль</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-center">
          <span class="d-flex flex-column align-items-center">
            Забыли пароль от аккаунта?
            <button class="btn btn-link text-decoration-none p-0" @click="switchTab('reset')">Восстановить</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const activeTab = ref('login');
const name = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const resetEmail = ref('');
const resetError = ref(null);
const resetSuccess = ref(false);
const agreedToTerms = ref(false);
const error = ref(null);
const authStore = useAuthStore();
const router = useRouter();
const toast = useToast();

const loading = computed(() => authStore.loading);

const switchTab = (tab) => {
  activeTab.value = tab;
};

const handleLogin = async () => {
  if (!loading.value) {
    try {
      await authStore.login(email.value, password.value);

      if (authStore.isAuthenticated()) {
        toast.info('Добро пожаловать');
        await router.push({ name: 'MainPage' });
      } else {
        toast.error('Неверный пароль. Попробуйте снова.');
      }
    } catch (err) {
      toast.error('Ошибка сервера. Попробуйте снова.');
    }
  }
};

const handleRegister = async () => {
  if (password.value !== confirmPassword.value) {
    error.value = 'Пароли не совпадают!';
    return;
  }

  const refCode = localStorage.getItem('referralCode') || null;

  if (!loading.value) {
    try {
      await authStore.register(name.value, email.value, password.value, refCode);

      if (authStore.isAuthenticated()) {
        localStorage.removeItem('referralCode');
        toast.info('Добро пожаловать');
        await router.push({ name: 'MainPage' });
      }
    } catch (err) {
      error.value = 'Произошла ошибка регистрации. Попробуйте снова.';
    }
  }
};

const handlePasswordReset = async () => {
  resetSuccess.value = false;
  resetError.value = null;

  if (!loading.value) {
    try {
      await authStore.forgotPassword(resetEmail.value);
      if (!authStore.error) {
        resetSuccess.value = true;
        toast.info('Письмо для сброса пароля отправлено');
      } else {
        resetError.value = authStore.error;
        toast.error('Ошибка при сбросе пароля');
      }
    } catch (err) {
      resetError.value = 'Произошла ошибка. Попробуйте снова.';
      toast.error('Ошибка при сбросе пароля');
    }
  }
};
</script>
