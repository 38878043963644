import { useAuthStore } from "@/stores/useAuthStore";
import { checkPaymentStatus, subscriptionDetail, subscriptionPayment } from "@/api/subscriptions/Subscriptions";
import { defineStore } from "pinia";

export const useSubscriptionsStore = defineStore('subscriptions', {
    state: () => ({
        error: null,
        loading: false,
        subscriptionDetail: null,
        selectedCurrency: null,
        selectedPeriod: null,
        paymentStatus: null,
    }),
    actions: {
        async fetchSubscriptionDetail(plan_id) {
            this.loading = true;
            try {
                const data = await subscriptionDetail(plan_id);
                this.error = null;
                this.subscriptionDetail = data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при получении деталей подписки';
            } finally {
                this.loading = false;
            }
        },

        async paySubscription(subscriptionId, currency, service) {
            this.loading = true;
            const authStore = useAuthStore();
            const token = authStore.token;

            if (!token) {
                this.error = 'Токен авторизации отсутствует';
                this.loading = false;
                return;
            }

            if (!currency || !service || !subscriptionId) {
                this.error = 'Не выбрана валюта, сервис оплаты или период';
                this.loading = false;
                return;
            }

            try {
                const data = await subscriptionPayment(token, subscriptionId, currency, service);
                this.error = null;
                return data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при выполнении оплаты';
            } finally {
                this.loading = false;
            }
        },

        async checkPaymentStatus(paymentId) {
            this.loading = true;
            const authStore = useAuthStore();
            const token = authStore.token;

            if (!token) {
                this.error = 'Токен авторизации отсутствует';
                this.loading = false;
                return;
            }

            try {
                const data = await checkPaymentStatus(token, paymentId);
                this.paymentStatus = data.status; // Обновляем статус платежа
                return data;
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при проверке статуса платежа';
            } finally {
                this.loading = false;
            }
        }
    }
});

