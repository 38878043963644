<template>
  <div class="table-responsive-outer scrollable" style="overflow: auto; max-height: 600px;">
    <div v-if="store.loading" class="d-flex justify-content-center align-items-center" style="height: 600px;">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <div v-else-if="store.top100Keywords.length === 0" class="d-flex flex-column justify-content-center align-items-center" style="height: 600px;">
      <h1 class="text-muted fs-4 mb-0">Ничего не нашлось</h1>
      <p class="mb-0 text-muted">попробуйте выбрать другой язык или период</p>
    </div>
    <div v-else class="table-responsive-inner">
      <table class="table mb-0 table-dark">
        <thead class="sticky-top">
        <tr>
          <th class="sticky-left nowrap ps-3">Ключ. слово</th>
          <th class="nowrap text-center">Показы в месяц</th>
          <th class="nowrap text-center">Конкуренция</th>
          <th class="nowrap text-center">Общий балл</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(keyword, index) in store.top100Keywords" :key="index" class="keywords-list">
          <td class="sticky-left nowrap text-muted">
            <div class="row">
              <div class="col-12 d-flex align-items-center ps-3">
                <button class="me-3 ps-0 pe-0 btn" @click="copyToClipboard(keyword.keyword)">
                  <font-awesome-icon icon="copy" />
                </button>
                <div
                    style="max-width: 200px;"
                    :class="{ keyword: canClickKeyword }"
                    @click="canClickKeyword && handleKeywordClick(keyword.keyword)">
                  {{ keyword.keyword }}
                </div>
              </div>
            </div>
          </td>
          <td class="text-center align-middle">{{ formatNumber(keyword.estimated_monthly_search) }}</td>
          <td class="nowrap text-center align-middle">
            <div class="badge-competition" :class="getCompetitionClass(keyword.competition)">
              {{ getCompetitionText(keyword.competition) }}
            </div>
          </td>
          <td class="nowrap text-center align-middle">
            <div class="badge-score" :class="getTotalScoreClass(keyword.overall)">
              {{ getTotalScoreText(keyword.overall) }}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';
import { useAuthStore } from '@/stores/useAuthStore';
import { formatNumber } from '@/utils/formatNumber';
import { useToast } from "vue-toastification";

const store = useKeywordsStore();
const toast = useToast();
const authStore = useAuthStore();

const competitionLevels = [
  { threshold: 80, text: 'очень высокая', class: 'high' },
  { threshold: 60, text: 'высокая', class: 'moderate' },
  { threshold: 40, text: 'средняя', class: 'medium' },
  { threshold: 20, text: 'низкая', class: 'low' },
  { threshold: 0, text: 'очень низкая', class: 'very-low' }
];

const scoreLevels = [
  { threshold: 80, text: 'очень высокий', class: 'very-low' },
  { threshold: 60, text: 'высокий', class: 'low' },
  { threshold: 40, text: 'средний', class: 'medium' },
  { threshold: 20, text: 'низкий', class: 'moderate' },
  { threshold: 0, text: 'очень низкий', class: 'high' }
];

const getLevelData = (value, levels) => {
  return levels.find(level => value >= level.threshold);
};

const getCompetitionText = (competition) => {
  return getLevelData(competition, competitionLevels).text;
};

const getCompetitionClass = (competition) => {
  return getLevelData(competition, competitionLevels).class;
};

const getTotalScoreText = (score) => {
  return getLevelData(score, scoreLevels).text;
};

const getTotalScoreClass = (score) => {
  return getLevelData(score, scoreLevels).class;
};

const hasActiveSubscription = computed(() => authStore.hasActive500Plan);
const canClickKeyword = computed(() => authStore.isAuthenticated() && hasActiveSubscription.value);

const handleKeywordClick = async (keyword) => {
  if (!canClickKeyword.value) return;

  try {
    await store.searchKeywords(keyword);
  } catch (error) {
    toast.error(`Ошибка при поиске ключевого слова: ${error.message}`);
  }
};

const copyToClipboard = async (keyword) => {
  if (!navigator.clipboard) {
    toast.error('Копирование не поддерживается в этом браузере');
    return;
  }

  try {
    await navigator.clipboard.writeText(keyword);
    toast.info('Ключевое слово скопировано');
  } catch (error) {
    toast.error(`Ошибка копирования: ${error.message}`);
  }
};

onMounted(async () => {
  await store.getTop100Keywords();
});
</script>

<style scoped>
.badge-competition, .badge-score {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  font-size: 13px;
  min-width: 100px;
}

.table td {
  vertical-align: middle;
}

.high {
  background-color: #ff0000;
  color: white;
}

.moderate {
  background-color: #ff4e4e;
  color: white;
}

.medium {
  background-color: #ff7a00;
  color: white;
}

.low {
  background-color: #008000;
  color: white;
}

.very-low {
  background-color: #005d00;
  color: white;
}

.btn:first-child:active {
  border-color: #ffffff00;
}

svg {
  color: var(--brend-color);
}

svg:hover {
  color: var(--text-color);
}

.keyword {
  cursor: pointer;
}

.keyword:hover {
  color: var(--brend-color);
}

/* Закрепляем шапку таблицы */
.table thead th {
  position: sticky;
  top: 0;
  z-index: 3;
  white-space: nowrap; /* Запрещаем перенос в шапке */
}

/* Предотвращаем сжатие остальных колонок */
.table th,
.table td {
  white-space: nowrap; /* Не переносим текст в ячейках по умолчанию */
  min-width: 150px; /* Минимальная ширина колонок */
}

/* Первая колонка (ключевое слово) с переносом текста и фиксированной шириной */
.table th.sticky-left,
.table td.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
  max-width: 200px; /* Ограничиваем ширину колонки */
  white-space: normal; /* Включаем перенос текста для первой колонки */
  word-wrap: break-word; /* Разрешаем перенос длинных слов */
}

/* Заголовок первой колонки должен быть выше по z-index */
.table th.sticky-left {
  z-index: 4;
}

/* Поддерживаем стики заголовков и первой колонки одновременно */
.table thead th.sticky-left {
  z-index: 5; /* Самый высокий уровень для заголовка первой колонки */
}
</style>