import axios from 'axios';
import { API_URL } from '@/api/config';

export const login = async (email, password) => {
    const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password
    });
    return response.data;
};

export const registration = async (name, email, password, ref) => {
    const response = await axios.post(`${API_URL}/auth/registration`, {
        name,
        email,
        password,
        ref
    });
    return response.data;
};

export const forgotPassword = async (email) => {
    const response = await axios.post(`${API_URL}/auth/forgot-password`, {
        email
    });
    return response.data;
};

export const resetPassword = async (token, newPassword, confirmPassword) => {
    const response = await axios.post(`${API_URL}/auth/reset-password`, {
        token,
        password: newPassword,
        password_confirmation: confirmPassword
    });
    return response.data;
};

export const getUserInfo = async (token) => {
    const response = await axios.get(`${API_URL}/me`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getUserSubscriptions = async (token) => {
    const response = await axios.get(`${API_URL}/me/subscriptions`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};