import { defineStore } from 'pinia';
import {forgotPassword, getUserInfo, getUserSubscriptions, login, registration, resetPassword} from "@/api/authApi";
import dayjs from 'dayjs';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        error: null,
        loading: false,
        user: JSON.parse(localStorage.getItem('user')) || null,
        token: localStorage.getItem('token') || null,
        userSubscriptions: JSON.parse(localStorage.getItem('userSubscriptions')) || [], // Подписки загружаются отдельно
    }),

    actions: {
        initStorageListener() {
            window.addEventListener('storage', this.syncWithLocalStorage);
        },

        syncWithLocalStorage() {
            this.token = localStorage.getItem('token');
            this.user = JSON.parse(localStorage.getItem('user'));
            this.userSubscriptions = JSON.parse(localStorage.getItem('userSubscriptions')); // Синхронизируем подписки
        },

        async login(email, password) {
            this.loading = true;

            try {
                const data = await login(email, password);

                this.error = null;

                if (data.access_token) {
                    localStorage.setItem('token', data.access_token);
                    this.token = data.access_token;

                    await this.fetchUserInfo(); // Сначала загружаем информацию о пользователе
                    await this.fetchUserSubscriptions(); // Затем его подписки
                }
            } catch (error) {
                this.error = error.response?.data?.error || 'Отказано в доступе';
            } finally {
                this.loading = false;
            }
        },

        async register(name, email, password, ref = null) {
            this.loading = true;

            try {
                const data = await registration(name, email, password, ref);

                this.error = null;

                if (data.access_token) {
                    localStorage.setItem('token', data.access_token);
                    this.token = data.access_token;

                    await this.fetchUserInfo();
                }
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка регистрации';
            } finally {
                this.loading = false;
            }
        },

        async forgotPassword(email) {
            this.loading = true;

            try {
                await forgotPassword(email);

                this.error = null;

            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при сбросе пароля';
            } finally {
                this.loading = false;
            }
        },

        async resetPassword(token, newPassword, confirmPassword) {
            this.loading = true;

            try {
                await resetPassword(token, newPassword, confirmPassword);

                this.error = null;

            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при сбросе пароля';
            } finally {
                this.loading = false;
            }
        },

        async fetchUserInfo() {
            if (this.token) {
                this.loading = true;
                try {
                    const userInfo = await getUserInfo(this.token);
                    this.user = userInfo;
                    localStorage.setItem('user', JSON.stringify(userInfo)); // Сохраняем только информацию о пользователе
                } catch (error) {
                    this.error = error.response?.data?.error || 'Не удалось загрузить данные пользователя';
                } finally {
                    this.loading = false;
                }
            }
        },

        async fetchUserSubscriptions() {
            if (this.token) {
                this.loading = true;
                try {
                    const userSubscriptions = await getUserSubscriptions(this.token);
                    this.userSubscriptions = userSubscriptions;
                    localStorage.setItem('userSubscriptions', JSON.stringify(userSubscriptions)); // Сохраняем подписки отдельно
                } catch (error) {
                    this.error = error.response?.data?.error || 'Не удалось загрузить подписки пользователя';
                } finally {
                    this.loading = false;
                }
            }
        },

        isAuthenticated() {
            return !!this.token;
        },

        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('userSubscriptions');
            this.token = null;
            this.user = null;
            this.userSubscriptions = [];
        }
    },

    getters: {
        hasActive500Plan: (state) => {
            const subscription500 = state.userSubscriptions.find(sub => sub.plan_id === 500);
            if (!subscription500) return false;

            return dayjs(subscription500.to * 1000).isAfter(dayjs());
        },

        subscription500EndDate: (state) => {
            const subscription500 = state.userSubscriptions.find(sub => sub.plan_id === 500);
            if (!subscription500) return null;
            return dayjs(subscription500.to * 1000);
        }
    }
});
