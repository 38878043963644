<template>
  <div>
    <h2>Активные подписки</h2>
    <ul v-if="userSubscriptions.length">
      <li v-for="(subscription, index) in userSubscriptions" :key="index">
        Подписка: {{ subscription.plan.name }} <br>
        Начало: {{ formatDateToLocal(subscription.from) }} <br>
        Окончание: {{ formatDateToLocal(subscription.to) }}
      </li>
    </ul>
    <p v-else>У вас нет активных подписок.</p>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';

const authStore = useAuthStore();
const userSubscriptions = computed(() => authStore.user?.paid || []);

const formatDateToLocal = (dateStr) => {
  const moscowOffset = 3 * 60;
  const utcTime = new Date(dateStr + ' UTC');

  return new Date(utcTime.getTime() + moscowOffset * 60 * 1000).toLocaleString();
};

</script>


<style scoped>
</style>