import axios from 'axios';
import { API_URL } from '@/api/config';

export const subscriptionDetail = async (plan_id) => {
    const response = await axios.get(`${API_URL}/subscriptions/details`, {
        params: {
            plan_id
        }
    });
    return response.data;
};

export const subscriptionPayment = async (token, subscription, currency, service) => {
    const response = await axios.post(`${API_URL}/subscriptions/payments`,
        {
            subscription,
            currency,
            service
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return response.data;
};

export const checkPaymentStatus = async (token, payment_id) => {
    const response = await axios.get(`${API_URL}/subscriptions/payments/status`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                payment_id
            }
        }
    );
    return response.data;
};