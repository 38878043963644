import axios from 'axios';
import { API_URL } from '@/api/config';

export const searchKeywords = async (token, keyword) => {
    const response = await axios.get(`${API_URL}/tools/v1/keywords`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            keyword
        }
    });
    return response.data;
};

export const searchKeyword = async (token, keyword) => {
    const response = await axios.get(`${API_URL}/public/tools/v1/keywords`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            keyword
        }
    });
    return response.data;
};

export const keywordExample = async () => {
    const response = await axios.get(`${API_URL}/public/tools/v1/keywords/example`);
    return response.data;
};

export const getTop100Keywords = async (period, language) => {
    const response = await axios.get(`${API_URL}/public/tools/v1/keywords/top`, {
        params: {
            period,
            language
        }
    });
    return response.data;
};
