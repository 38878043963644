<template>
  <nav aria-label="breadcrumb" class="alert alert-primary rounded-4 pt-1 pb-1">
    <ol class="breadcrumb m-0" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </router-link>
        <meta itemprop="position" content="1">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/tools" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/tools">
          <span itemprop="name">Инструменты</span>
        </router-link>
        <meta itemprop="position" content="2">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/tools/v1" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/tools/v1">
          <span itemprop="name">v1</span>
        </router-link>
        <meta itemprop="position" content="3">
      </li>
      <li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <span itemprop="name">Поиск ключевых слов</span>
        <meta itemprop="position" content="4">
      </li>
    </ol>
  </nav>
  <div class="card rounded-4 position-relative">
    <div class="card-body">
      <h1 class="mb-0 fs-4 text-center">Поиск ключевых слов</h1>
    </div>
    <search-form />
    <div class="card-footer d-flex flex-column flex-md-row align-items-center justify-content-between" v-if="!store.keywordsSearchResult && !store.keywordSearchResult">
      <h2 class="mb-0 fs-5 w-100 text-md-start text-center">ТОП-100 ключевых слов на YouTube</h2>
      <div class="selects d-flex mt-2 mt-md-0">
        <period-selection />
        <language-selection />
      </div>
    </div>
    <div class="card-footer ps-2 pe-2">
      <main-keyword v-if="store.keywordsSearchResult" />
      <free-keyword-search-result v-else-if="store.keywordSearchResult" />
      <top100-result v-else />
    </div>
    <template v-if="store.keywordsSearchResult">
      <div class="card-footer">
        <ul class="nav nav-tabs nav-pills nav-fill" id="keywordTabs" role="tablist">
          <li class="nav-item me-0 me-lg-3 mb-2 mb-sm-0" role="presentation">
            <button class="nav-link rounded-4 active" id="similar-tab" data-bs-toggle="tab" data-bs-target="#similar" type="button" role="tab" aria-controls="similar" aria-selected="false" :disabled="store.loading" @click="changeTab('similar')">Похожие</button>
          </li>
          <li class="nav-item me-0 me-lg-3 mb-2 mb-sm-0" role="presentation">
            <button class="nav-link rounded-4" id="related-tab" data-bs-toggle="tab" data-bs-target="#related" type="button" role="tab" aria-controls="related" aria-selected="true" :disabled="store.loading" @click="changeTab('related')">Связанные</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link rounded-4" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true" :disabled="store.loading" @click="changeTab('questions')">Вопросного типа</button>
          </li>
        </ul>
      </div>
      <div class="card-footer ps-2 pe-2">
        <div class="tab-content">
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade show active" id="similar" role="tabpanel" aria-labelledby="similar-tab">
            <related-result />
          </div>
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade" id="related" role="tabpanel" aria-labelledby="related-tab">
            <permutations-result />
          </div>
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade" id="questions" role="tabpanel" aria-labelledby="questions-tab">
            <questions-result />
          </div>
        </div>
      </div>
    </template>
    <template v-if="store.keywordSearchResult">
      <div class="card-footer">
        <ul class="nav nav-tabs nav-pills nav-fill" id="keywordTabs" role="tablist">
          <li class="nav-item me-0 me-lg-3 mb-2 mb-sm-0" role="presentation">
            <button class="nav-link rounded-4 active" id="similar-tab" data-bs-toggle="tab" data-bs-target="#similar" type="button" role="tab" aria-controls="similar" aria-selected="false" :disabled="store.loading" @click="changeTab('similar')">Похожие</button>
          </li>
          <li class="nav-item me-0 me-lg-3 mb-2 mb-sm-0" role="presentation">
            <button class="nav-link rounded-4" id="related-tab" data-bs-toggle="tab" data-bs-target="#related" type="button" role="tab" aria-controls="related" aria-selected="true" :disabled="store.loading" @click="changeTab('related')">Связанные</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link rounded-4" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true" :disabled="store.loading" @click="changeTab('questions')">Вопросного типа</button>
          </li>
        </ul>
      </div>
      <div class="card-footer">
        <div class="tab-content">
          <div class="tab-pane fade show active" id="similar" role="tabpanel" aria-labelledby="similar-tab">
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 300px;">
              <h1 class="text-muted fs-4 mb-2">Упс...</h1>
              <div class="text-center" style="max-width: 600px;">
                <p class="mb-3 text-muted">
                  Чтобы видеть «Похожие» ключевые слова,
                  нужно иметь активную подписку на инструмент.
                </p>
                <p class="mb-0 text-muted">
                  Перед покупкой подписки, вы можете ознакомиться с
                  <router-link class="text-decoration-none" to="/tools/v1/keywords/example">примером</router-link>.
                  <br>Такой же результат вы будете видеть с активной подпиской на инструмент.
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="related" role="tabpanel" aria-labelledby="related-tab">
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 300px;">
              <h1 class="text-muted fs-4 mb-2">Упс...</h1>
              <div class="text-center" style="max-width: 600px;">
                <p class="mb-3 text-muted">
                  Чтобы видеть «Связанные» ключевые слова,
                  нужно иметь активную подписку на инструмент.
                </p>
                <p class="mb-0 text-muted">
                  Перед покупкой подписки, вы можете ознакомиться с
                  <router-link class="text-decoration-none" to="/tools/v1/keywords/example">примером</router-link>.
                  <br>Такой же результат вы будете видеть с активной подпиской на инструмент.
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="questions" role="tabpanel" aria-labelledby="questions-tab">
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 300px;">
              <h1 class="text-muted fs-4 mb-2">Упс...</h1>
              <div class="text-center" style="max-width: 600px;">
                <p class="mb-3 text-muted">
                  Чтобы видеть ключевые слова «Вопросного типа»,
                  нужно иметь активную подписку на инструмент.
                </p>
                <p class="mb-0 text-muted">
                  Перед покупкой подписки, вы можете ознакомиться с
                  <router-link class="text-decoration-none" to="/tools/v1/keywords/example">примером</router-link>.
                  <br>Такой же результат вы будете видеть с активной подпиской на инструмент.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onUnmounted, ref } from 'vue';
import { useKeywordsStore } from "@/stores/tools/v1/keywords/useKeywordsStore";

import SearchForm from "@/components/tools/v1/keywords/SearchForm.vue";
import PeriodSelection from "@/components/tools/v1/keywords/top100/Selects/PeriodSelection.vue";
import LanguageSelection from "@/components/tools/v1/keywords/top100/Selects/LanguageSelection.vue";
import MainKeyword from "@/components/tools/v1/keywords/result/MainKeyword.vue";
import RelatedResult from "@/components/tools/v1/keywords/result/RelatedResult.vue";
import QuestionsResult from "@/components/tools/v1/keywords/result/QuestionsResult.vue";
import Top100Result from "@/components/tools/v1/keywords/top100/Top100Result.vue";
import FreeKeywordSearchResult from "@/components/tools/v1/keywords/free/FreeKeywordSearchResult.vue";
import PermutationsResult from "@/components/tools/v1/keywords/result/PermutationsResult.vue";

const store = useKeywordsStore();
const activeTab = ref('similar');

const changeTab = (tabName) => {
  activeTab.value = tabName;
};

onUnmounted(() => {
  store.keywordsSearchResult = null;
  store.keywordSearchResult = null;
  store.exampleKeywordsSearchResult = null;
  store.currentKeyword = null;
});
</script>

<style scoped>
.tab-pane {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>