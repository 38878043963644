<template>
  <div class="row justify-content-center align-items-center" style="height: 70vh;">
    <div class="col-12 col-md-5">
      <div class="card rounded-4">
        <div class="card-header">
          <h1 class="text-center mb-0 fs-4">Восстановление пароля</h1>
        </div>
        <div class="card-body">
          <form @submit.prevent="handlePasswordReset">
            <input v-model="password" type="password" class="form-control rounded-4 mb-2" placeholder="Придумайте хороший пароль" required>
            <input v-model="confirmPassword" type="password" class="form-control rounded-4 mb-3" placeholder="Повторите пароль" required>
            <button :disabled="loading" type="submit" class="btn btn-outline-primary rounded-4 w-100">
              <span v-if="loading">Изменяю пароль...</span>
              <span v-else>Изменить пароль</span>
            </button>
          </form>
          <p v-if="error" class="text-danger mt-3">{{ error }}</p>
          <p v-if="success" class="text-success mt-3">Пароль успешно изменён! Перенаправление на страницу входа...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';
import { useRouter } from 'vue-router';

const password = ref('');
const confirmPassword = ref('');
const loading = ref(false);
const error = ref(null);
const success = ref(false);
const authStore = useAuthStore();
const router = useRouter();

const handlePasswordReset = async () => {
  const token = localStorage.getItem('forgotPasswordToken');

  if (!token) {
    error.value = 'Токен не найден. Попробуйте снова.';
    return;
  }

  if (password.value !== confirmPassword.value) {
    error.value = 'Пароли не совпадают.';
    return;
  }

  loading.value = true;
  error.value = null;
  success.value = false;

  try {
    await authStore.resetPassword(token, password.value, confirmPassword.value);

    if (!authStore.error) {
      success.value = true;
      localStorage.removeItem('forgotPasswordToken');

      // Через несколько секунд перенаправить на страницу входа
      setTimeout(() => {
        router.push({ name: 'AuthPage' });
      }, 2000);
    } else {
      error.value = authStore.error;
    }
  } catch (err) {
    error.value = 'Произошла ошибка. Попробуйте снова.';
  } finally {
    loading.value = false;
  }
};
</script>
