import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from "@/stores/useAuthStore";
import NProgress from 'nprogress';

import MainPage from "@/views/MainPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import ToolsPage from "@/views/tools/ToolsPage.vue";
import KeywordsPage from "@/views/tools/v1/keywords/KeywordsPage.vue";
import AuthPage from "@/views/AuthPage.vue";
import MePage from "@/views/MePage.vue";
import MySubscriptionsPage from "@/views/me/MySubscriptionsPage.vue";
import SubscriptionsPage from "@/views/subscriptions/SubscriptionsPage.vue";
import SettingsPage from "@/views/me/SettingsPage.vue";
import ExampleKeywordsPage from "@/views/tools/v1/keywords/example/ExampleKeywordsPage.vue";
import SubscriptionKeywordsPage from "@/views/subscriptions/v1/SubscriptionKeywordsPage.vue";
import ConfidentialPage from "@/views/docs/ConfidentialPage.vue";
import OfferPage from "@/views/docs/OfferPage.vue";
import AuthForgotPage from "@/views/AuthForgotPage.vue";
// import SmartSearchChannelsPage from "@/views/tools/beta/smartSearch/SmartSearchChannelsPage.vue";

const routes = [
    {
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    {
        path: '/auth',
        name: 'AuthPage',
        component: AuthPage,
        meta: {
            title: 'Авторизация'
        }
    },
    {
        path: '/auth/forgot/:token',
        name: 'AuthForgotPage',
        component: AuthForgotPage,
        meta: {
            title: 'Восстановление пароля'
        },
        beforeEnter: (to, from, next) => {
            const token = to.params.token;
            if (token) {
                localStorage.setItem('forgotPasswordToken', token);
            }
            next();
        }
    },
    {
        path: '/me',
        name: 'MePage',
        component: MePage,
        children: [
            {
                path: 'subscriptions',
                name: 'MySubscriptionsPage',
                component: MySubscriptionsPage
            },
            {
                path: 'settings',
                name: 'SettingsPage',
                component: SettingsPage
            }
        ]
    },
    {
        path: '/tools',
        name: 'ToolsPage',
        component: ToolsPage,
        meta: {
            title: 'Инструменты сервиса'
        }
    },
    {
        path: '/tools/v1/keywords',
        name: 'KeywordsPage',
        component: KeywordsPage,
        meta: {
            title: 'Поиск ключевых слов'
        }
    },
    {
        path: '/tools/v1/keywords/example',
        name: 'ExampleKeywordsPage',
        component: ExampleKeywordsPage,
        meta: {
            title: 'Поиск ключевых слов (Пример)'
        }
    },
    // {
    //     path: '/tools/beta/smart-search-channels',
    //     name: 'SmartSearchChannelsPage',
    //     component: SmartSearchChannelsPage,
    //     meta: {
    //         title: 'Умный поиск'
    //     }
    // },
    {
        path: '/subscriptions',
        name: 'SubscriptionsPage',
        component: SubscriptionsPage,
        meta: {
            title: 'Подписки сервиса'
        }
    },
    {
        path: '/subscriptions/v1/keywords',
        name: 'SubscriptionKeywordsPage',
        component: SubscriptionKeywordsPage,
        meta: {
            title: 'Подписка на Поиск ключевых слов'
        }
    },
    {
        path: '/tools/v1',
        redirect: '/tools'
    },
    {
        path: '/tools/beta',
        redirect: '/tools'
    },
    {
        path: '/auth/forgot',
        redirect: '/auth'
    },
    {
        path: '/subscriptions/v1',
        redirect: '/subscriptions'
    },
    {
        path: '/confidential',
        name: 'ConfidentialPage',
        component: ConfidentialPage,
        meta: {
            title: 'Политика конфиденциальности'
        }
    },
    {
        path: '/offer',
        name: 'OfferPage',
        component: OfferPage,
        meta: {
            title: 'Публичная оферта'
        }
    },
    {
        path: '/p/:referralCode',
        name: 'ReferralCodePage',
        beforeEnter: (to, from, next) => {
            localStorage.setItem('referralCode', to.params.referralCode);
            next({ name: 'MainPage' });
        }
    },
    {
        path: '/register/:referralCode',
        name: 'ReferralCodePage1',
        beforeEnter: (to, from, next) => {
            localStorage.setItem('referralCode', to.params.referralCode);
            next({ name: 'MainPage' });
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage',
        component: NotFoundPage
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();

    if (!authStore.isAuthenticated() && to.path.startsWith('/me')) {
        next({ name: 'AuthPage' });
    } else if (authStore.isAuthenticated() && to.name === 'AuthPage') {
        next({ name: 'MainPage' });
    } else {
        next();
    }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach((to) => {
    NProgress.done();

    const defaultTitle = 'Youme';
    document.title = to.meta.title ? `${to.meta.title} / ${defaultTitle}` : defaultTitle;
});


export default router;
