<template>
  <header class="fixed-top">
    <nav class="navbar navbar-expand-sm user-select-none p-0">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="logo d-flex align-items-center">
          <router-link class="text-decoration-none fs-4" to="/">Youme</router-link>
        </div>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav" v-if="showMenu">
          <div class="menu-wrapper">
            <ul class="navbar-nav flex-nowrap ms-4 me-4">
              <li class="nav-item position-relative me-4" :class="{ active: isActiveRoute('/tools') }">
                <router-link class="text-decoration-none" to="/tools">Инструменты</router-link>
              </li>
              <li class="nav-item position-relative me-4" :class="{ active: isActiveRoute('/subscriptions') }">
                <router-link class="text-decoration-none" to="/subscriptions">Подписки</router-link>
              </li>
              <li class="nav-item position-relative me-4" :class="{ active: isActiveRoute('/bot') }">
                <span>
                  Бот
                  <span class="badge">Скоро</span>
                </span>
              </li>
              <li class="nav-item position-relative" :class="{ active: isActiveRoute('/extension') }">
              <span>
                Расширение
                <span class="badge">Скоро</span>
              </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="user && isAuthenticated" class="d-flex align-items-center dropdown pt-md-0 pb-md-0 pt-2 pb-2" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <img class="rounded-circle me-1" :src="`https://api.youme.gg/storage/${user.profile_image}`" alt="Avatar" style="width: 35px; height: 35px;">
          <font-awesome-icon icon="angle-down" style="font-size: 20px;" />
          <ul class="dropdown-menu dropdown-menu-end mt-3" aria-labelledby="dropdownMenuButton">
<!--            <li class="dropdown-item" @click="goToMyProfile">Мой профиль</li>-->
<!--            <li class="dropdown-item" @click="goToSubscriptions">Мои подписки</li>-->
<!--            <li class="dropdown-item" @click="goToSettings">Настройки</li>-->
<!--            <li>-->
<!--              <hr class="dropdown-divider">-->
<!--            </li>-->
            <li class="dropdown-item" @click="handleLogout">Выход</li>
          </ul>
        </div>
        <router-link v-else class="text-decoration-none btn btn-outline-primary rounded-4 mt-2 mb-2 d-flex align-items-center" to="/auth">
          <font-awesome-icon icon="right-to-bracket" />
          <span class="d-lg-block d-md-none">Авторизация</span>
        </router-link>
      </div>
    </nav>
    <ul v-if="!showMenu" class="mobile-menu navbar-nav flex-nowrap border-top ms-md-0 me-md-0 me-4 ms-3">
      <li class="nav-item position-relative pt-2 pb-2 me-4" :class="{ active: isActiveRoute('/tools') }">
        <router-link class="text-decoration-none" to="/tools">Инструменты</router-link>
      </li>
      <li class="nav-item position-relative pt-2 pb-2 me-4" :class="{ active: isActiveRoute('/subscriptions') }">
        <router-link class="text-decoration-none" to="/subscriptions">Подписки</router-link>
      </li>
      <li class="nav-item position-relative pt-2 pb-2 me-4" :class="{ active: isActiveRoute('/bot') }">
        <span>Бот<span class="badge">Скоро</span></span>
      </li>
      <li class="nav-item position-relative pt-2 pb-2" :class="{ active: isActiveRoute('/extension') }">
        <span>Расширение<span class="badge">Скоро</span></span>
      </li>
    </ul>
  </header>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { useAuthStore } from '@/stores/useAuthStore';
import router from "@/router/router";

const route = useRoute();
const authStore = useAuthStore();

const isAuthenticated = computed(() => authStore.isAuthenticated());
const user = computed(() => authStore.user);
const showMenu = ref(true);

// Методы навигации
// const goToMyProfile = () => {
//   router.push('/me');
// };
//
// const goToSubscriptions = () => {
//   router.push('/me/subscriptions');
// };
//
// const goToSettings = () => {
//   router.push('/me/settings');
// };

const handleLogout = () => {
  authStore.logout();
  router.push('/auth');
};

// Функция проверки активного роута
const isActiveRoute = (path) => {
  return route.path.startsWith(path);
};

// Функция для проверки ширины окна
const checkWindowWidth = () => {
  showMenu.value = window.innerWidth > 767;
};

// Слушатели для изменения размера окна
onMounted(() => {
  checkWindowWidth(); // Проверить ширину при загрузке компонента
  window.addEventListener('resize', checkWindowWidth); // Добавить слушатель на изменение размера окна
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkWindowWidth); // Удалить слушатель при уничтожении компонента
});
</script>

<style scoped>
.logo > a {
  font-weight: bold;
  color: var(--title-color);
}

.nav-item {
  padding: 15px 0;
}

.nav-item>a,.nav-item>div {
  opacity: 0.8;
  color: var(--text-color);
  font-weight: bold;
}

.router-link-active {
  color: var(--title-color)!important;
}

.nav-item > a:hover {
  opacity: 1;
}

.active:after {
  content: '';
  display: block;
  position: absolute;
  height: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0098ea;
  border-radius: 3px 3px 0 0;
}

svg {
  width: 30px;
  opacity: 0.5;
}

svg:hover {
  opacity: 1;
}

.btn svg {
  opacity: 1;
}

.dropdown {
  cursor: pointer!important;
}

.mobile-menu {
  display: flex;
  flex-direction: row; /* Убедиться, что элементы расположены в строку */
  justify-content: space-around; /* Или другое выравнивание, например, center или start */
  align-items: center; /* Вертикальное выравнивание */
  padding: 0;
  margin: 0;
  list-style-type: none;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.mobile-menu > li {
  display: inline-block;
}

.mobile-menu.nav-item {
  flex: 0 0 auto;
}

.mobile-menu {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}

.mobile-menu::-webkit-scrollbar {
  display: none;  /* Для Chrome, Safari и Opera */
}

</style>
