<template>
  <nav aria-label="breadcrumb" class="alert alert-primary rounded-4 pt-1 pb-1">
    <ol class="breadcrumb m-0" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </router-link>
        <meta itemprop="position" content="1">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/tools" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/tools">
          <span itemprop="name">Инструменты</span>
        </router-link>
        <meta itemprop="position" content="2">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/tools/v1" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/tools/v1">
          <span itemprop="name">v1</span>
        </router-link>
        <meta itemprop="position" content="3">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/tools/v1/keywords" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/tools/v1/keywords">
          <span itemprop="name">Поиск ключевых слов</span>
        </router-link>
        <meta itemprop="position" content="4">
      </li>
      <li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <span itemprop="name">Пример</span>
        <meta itemprop="position" content="5">
      </li>
    </ol>
  </nav>
  <div class="card rounded-4">
    <div class="card-body">
      <h1 class="mb-0 fs-4 text-center">Поиск ключевых слов</h1>
    </div>
    <div class="card-footer pt-0">
      <example-keyword-search-result />
    </div>
    <template v-if="store.exampleKeywordsSearchResult">
      <div class="card-footer">
        <ul class="nav nav-tabs nav-pills nav-fill" id="keywordTabs" role="tablist">
          <li class="nav-item me-0 me-xxl-3 me-xl-3 me-lg-3 mb-2 mb-lg-0" role="presentation">
            <button class="nav-link rounded-4 active" id="similar-tab" data-bs-toggle="tab" data-bs-target="#similar" type="button" role="tab" aria-controls="similar" aria-selected="false" :disabled="store.loading" @click="changeTab('similar')">Похожие</button>
          </li>
          <li class="nav-item me-0 me-xxl-3 me-xl-3 me-lg-3 mb-2 mb-lg-0" role="presentation">
            <button class="nav-link rounded-4" id="related-tab" data-bs-toggle="tab" data-bs-target="#related" type="button" role="tab" aria-controls="related" aria-selected="true" :disabled="store.loading" @click="changeTab('related')">Связанные</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link rounded-4" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true" :disabled="store.loading" @click="changeTab('questions')">Вопросного типа</button>
          </li>
        </ul>
      </div>
      <div class="card-footer ps-2 pe-2">
        <div class="tab-content">
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade show active" id="similar" role="tabpanel" aria-labelledby="similar-tab">
            <example-related-result />
          </div>
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade" id="related" role="tabpanel" aria-labelledby="related-tab">
            <example-permutations-result />
          </div>
          <div style="overflow: auto; max-height: 710px;" class="tab-pane fade" id="questions" role="tabpanel" aria-labelledby="questions-tab">
            <example-questions-result />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import { useKeywordsStore } from "@/stores/tools/v1/keywords/useKeywordsStore";

import ExampleKeywordSearchResult from "@/components/tools/v1/keywords/example/ExampleKeywordSearchResult.vue";
import ExampleQuestionsResult from "@/components/tools/v1/keywords/example/ExampleQuestionsResult.vue";
import ExampleRelatedResult from "@/components/tools/v1/keywords/example/ExampleRelatedResult.vue";
import ExamplePermutationsResult from "@/components/tools/v1/keywords/example/ExamplePermutationsResult.vue";

const store = useKeywordsStore();
const activeTab = ref('related');

const changeTab = (tabName) => {
  activeTab.value = tabName;
};

onMounted(() => {
  store.exampleSearchKeywords();
});
</script>

<style scoped>
.tab-pane {
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
  scrollbar-width: none;  /* Для Firefox */
}
</style>