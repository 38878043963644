<template>
  <nav aria-label="breadcrumb" class="alert alert-primary rounded-4 pt-1 pb-1">
    <ol class="breadcrumb m-0" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </router-link>
        <meta itemprop="position" content="1">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/subscriptions" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/subscriptions">
          <span itemprop="name">Подписки</span>
        </router-link>
        <meta itemprop="position" content="2">
      </li>
      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <router-link class="text-decoration-none" to="/subscriptions/v1" itemscope itemtype="https://schema.org/WebPage" itemprop="item" itemid="/subscriptions/v1">
          <span itemprop="name">v1</span>
        </router-link>
        <meta itemprop="position" content="3">
      </li>
      <li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <span itemprop="name">Поиск ключевых слов</span>
        <meta itemprop="position" content="4">
      </li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-12 col-lg-8 col-md-7">
      <div class="card rounded-4">
        <div class="card-body">
          <h1 class="mb-0 fs-6 text-center">Поиск ключевых слов 1.0</h1>
        </div>
        <template v-if="isQrCode">
          <div class="card-footer">
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Подписка:</div>
                <span>Поиск ключевых слов 1.0</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Период:</div>
                <span>{{ selectedPeriodText }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Способ оплаты:</div>
                <span>{{ selectedPaymentMethodText }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Сумма к оплате:</div>
                <span>{{ selectedPriceText }}</span>
              </li>
            </ul>
          </div>
          <div class="card-footer text-center">Инструкция по оплате</div>
          <div class="card-footer">
            <ul class="m-0 p-0 qr-help">
              <li>1. Откройте приложение вашего банка.</li>
              <li>2. На главном экране или в разделе «Платежи и переводы» нажмите на кнопку «По QR-коду».</li>
              <li>3. Отсканируйте QR-код.</li>
              <li>4. Подтвердите оплату в приложении банка.</li>
            </ul>
          </div>
        </template>
        <template v-else-if="isPaymentLink">
          <div class="card-footer">
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Подписка:</div>
                <span>Поиск ключевых слов 1.0</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Период:</div>
                <span>{{ selectedPeriodText }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Способ оплаты:</div>
                <span>{{ selectedPaymentMethodText }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="me-auto">Сумма к оплате:</div>
                <span>{{ selectedPriceText }}</span>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <div class="d-flex flex-column align-items-center">
              <div class="spinner-border mb-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="mb-0">Ожидаю оплату...</p>
            </div>
          </div>
          <div class="card-footer">
            <p class="mb-0">
              Если по какой-то причине вас автоматически не перенаправило на
              платёжную систему Cryptomus, то вы можете сделать это, перейдя по ссылке:
              <a :href="`${paymentLink}`" target="_blank" class="text-decoration-none">{{paymentLink}}</a>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="card-footer">Что даёт подписка:</div>
          <div class="card-footer">
            <ul class="p-0 m-0">
              <li class="mb-2">1. Дополнительные ключевые слова из вкладок «Похожие», «Связанные», «Вопросного типа». А так же, удобные фильтры, позволяющие делать их фильтрацию.</li>
              <li class="mb-2">2. Экспорт ключевых слов в трёх форматах: XLSX, JSON, CSV, для дальнейшей работы и собственного анализа.</li>
              <li>3. Массовое копирование ключевых слов: вы сможете выделить нужные вам ключевые слова и скопировать их нажатием одной кнопки. Ключевые слова будут скопированы через запятую.</li>
            </ul>
          </div>
          <div class="card-footer">
            Так же, перед покупкой, вы можете
            <router-link class="btn-link text-decoration-none p-0" to="/tools/v1/keywords/example">ознакомиться</router-link>
            с полным функционалом инструмента.
          </div>
        </template>
      </div>
    </div>
    <div class="col-12 col-lg-4 col-md-5 order-first order-md-last mb-lg-0 mb-4">
      <div class="card rounded-4" v-if="isQrCode">
        <div class="card-body">
          <h1 class="fs-6 mb-0 text-center">Оплата через СБП</h1>
        </div>
        <div class="card-footer p-0">
          <img :src="qrCodeUrl" alt="QR Code" style="width: 100%;" />
        </div>
      </div>
      <div class="card rounded-4" v-else>
        <div class="card-body" v-if="!authStore.isAuthenticated()">
          <h2 class="fs-6 mb-0 text-center">Предпросмотр цен</h2>
        </div>
        <div class="card-body" v-else-if="authStore.isAuthenticated() && !authStore.hasActive500Plan">
          <h2 class="fs-6 mb-0 text-center">Покупка подписки</h2>
        </div>
        <div class="card-body" v-else-if="authStore.hasActive500Plan">
          <h2 class="fs-6 mb-0 text-center">Продление подписки</h2>
        </div>
        <div class="card-footer p-2 text-center">Способы оплаты</div>
        <div class="card-footer">
          <div class="row">
            <div v-for="(method, index) in sortedPaymentMethods" :key="index" class="col-6">
              <div
                  class="payment-method d-flex align-items-center justify-content-center"
                  :class="{ active: selectedCurrency === method.currency, disabled: store.loading || isPaymentLink }"
                  @click="!store.loading && selectPaymentMethod(method)"
              >
                <img :src="getIcon(method.currency)" class="me-2 rounded-circle" style="width: 20px;" alt="" />
                <span>{{ getMethodName(method.currency) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer p-2 text-center">Период</div>
        <div class="card-footer">
          <div class="row" v-if="store.subscriptionDetail && store.subscriptionDetail.options">
            <div
                v-for="option in store.subscriptionDetail.options"
                :key="option.id"
                class="col-6"
            >
              <div
                  class="payment-method text-center"
                  :class="{ disabled: !selectedCurrency || store.loading || isPaymentLink, active: selectedPeriod === option }"
                  @click="selectPeriod(option)"
                  :style="{ cursor: !selectedCurrency ? 'not-allowed' : 'pointer' }"
              >
                {{ getPeriodText(option.length) }}
              </div>
            </div>
          </div>
          <div v-else>
            <p>Детали подписки не загружены</p>
          </div>
        </div>
        <div class="card-footer p-2 text-center">
          <template v-if="!selectedCurrency">
            <span>Выберите способ оплаты</span>
          </template>
          <template v-else-if="!selectedPeriod">
            <span>Выберите период</span>
          </template>
          <template v-else>
            <span>Сумма к оплате: <b>{{ selectedPrice }} {{ selectedCurrency }}</b></span>
          </template>
        </div>
        <div v-if="selectedCurrency && selectedPeriod && authStore.isAuthenticated()" class="card-footer p-2">
          <button class="btn btn-outline-primary rounded-4 w-100" @click="pay" :disabled="store.loading || isPaymentLink">
            Оплатить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useSubscriptionsStore } from '@/stores/subscriptions/useSubscriptionsStore';
import { useAuthStore } from "@/stores/useAuthStore";
import { useToast } from 'vue-toastification';

import sbpIcon from '@/assets/icons/sbp.svg';
import usdtIcon from '@/assets/icons/usdt.svg';
import paySuccessSound from '@/assets/sounds/pay-succes.mp3';
import router from "@/router/router";

const store = useSubscriptionsStore();
const authStore = useAuthStore();
const isLoadingSubscriptionDetail = ref(false);

const paymentMethods = ref([]);
const selectedCurrency = ref('');
const selectedPeriod = ref(null);
const selectedPrice = ref(0);
const selectedSubscriptionId = ref('');
const qrCodeUrl = ref(null);
const paymentLink = ref(null);
const paymentId = ref(null);
const isCheckingPayment = ref(false);
const toast = useToast();
const successSound = new Audio(paySuccessSound);
let checkInterval = null;

const selectedPaymentMethodText = computed(() => {
  return selectedCurrency.value === 'RUB' ? 'СБП' : 'USDT';
});

const selectedPeriodText = computed(() => {
  if (!selectedPeriod.value) return 'Не выбран';

  switch (selectedPeriod.value.length) {
    case '1d':
      return '1 день';
    case '1m':
      return '1 месяц';
    default:
      return selectedPeriod.value.length;
  }
});

const getPeriodText = (length) => {
  switch (length) {
    case '1d':
      return '1 день';
    case '1m':
      return '1 месяц';
    default:
      return length;
  }
};

const selectedPriceText = computed(() => {
  return `${selectedPrice.value} ${selectedCurrency.value === 'RUB' ? 'рублей' : 'USD'}`;
});

const methodNames = {
  RUB: 'СБП',
  USD: 'USDT'
};

const getIcon = (currency) => {
  switch (currency) {
    case 'RUB': return sbpIcon;
    case 'USD': return usdtIcon;
    default: return '';
  }
};

const getMethodName = (currency) => methodNames[currency] || currency;

const sortedPaymentMethods = computed(() => {
  const currenciesOrder = ['RUB', 'USD'];
  const uniqueMethods = paymentMethods.value.filter((method, index, self) =>
      index === self.findIndex((m) => m.currency === method.currency)
  );
  return uniqueMethods.sort((a, b) => {
    return currenciesOrder.indexOf(a.currency) - currenciesOrder.indexOf(b.currency);
  });
});

const selectPaymentMethod = (method) => {
  selectedCurrency.value = method.currency;
  updatePrice();
};

const selectPeriod = (option) => {
  if (!selectedCurrency.value) return;
  selectedPeriod.value = option;
  updatePrice();
  selectedSubscriptionId.value = option.id;
};

const updatePrice = () => {
  if (selectedPeriod.value && selectedCurrency.value) {
    const priceObj = selectedPeriod.value.prices.find(price => price.currency === selectedCurrency.value);
    selectedPrice.value = priceObj ? priceObj.price : 0;
  }
};

const startUsdtPaymentCheck = () => {
  setTimeout(() => {
    startPaymentCheck(5000);
  }, 5000);
};

const pay = async () => {
  try {
    const paymentMethod = selectedCurrency.value === 'USD' ? 'cryptomus' : 'paykeeper';

    const result = await store.paySubscription(selectedSubscriptionId.value, selectedCurrency.value, paymentMethod);
    if (result && result.url && result.id) {
      paymentId.value = result.id;
      if (result.url.startsWith('data:image')) {
        qrCodeUrl.value = result.url;
        startPaymentCheck(5000);  // Начинаем проверку статуса сразу после генерации QR-кода
      } else {
        paymentLink.value = result.url;
        window.open(paymentLink.value, '_blank');
        // Если это метод USDT, начинаем проверку статуса через 5 секунд
        if (selectedCurrency.value === 'USD') {
          startUsdtPaymentCheck();
        }
      }
    }
  } catch (error) {
    console.error('Ошибка оплаты:', error);
  }
};

const startPaymentCheck = (intervalMs = 5000) => {
  isCheckingPayment.value = true;
  checkInterval = setInterval(async () => {
    const result = await store.checkPaymentStatus(paymentId.value);
    if (result && (result.status === 'success' || result.status === 'manual:success' || result.status === 'paid')) {
      clearInterval(checkInterval);
      isCheckingPayment.value = false;
      toast.info("Подписка на инструмент успешно куплена");
      await successSound.play();
      await authStore.fetchUserSubscriptions();
      await router.push('/subscriptions');
    }
  }, intervalMs);
};

// Флаги для определения типа URL
const isQrCode = computed(() => !!qrCodeUrl.value);
const isPaymentLink = computed(() => !!paymentLink.value);

onMounted(async () => {
  isLoadingSubscriptionDetail.value = true;

  try {
    const result = await store.paySubscription(selectedSubscriptionId.value, selectedCurrency.value, 'paykeeper');
    if (result && result.url) {
      // Обработка URL
      if (result.url.startsWith('data:image')) {
        qrCodeUrl.value = result.url;
        startPaymentCheck(2000);  // Начинаем проверку сразу после получения QR-кода
      } else {
        paymentLink.value = result.url;
        // Открываем ссылку в новой вкладке
        window.open(paymentLink.value, '_blank');
        // Если это метод USDT, начинаем проверку статуса через 5 секунд
        if (selectedCurrency.value === 'USD') {
          startUsdtPaymentCheck();
        }
      }
      paymentId.value = result.id;
    }

    await store.fetchSubscriptionDetail(500);

    if (store.subscriptionDetail && store.subscriptionDetail.options) {
      paymentMethods.value = store.subscriptionDetail.options.flatMap(option => option.prices);
    } else {
      console.error('Не удалось загрузить детали подписки или отсутствуют опции');
    }
  } finally {
    isLoadingSubscriptionDetail.value = false;
  }
});

onUnmounted(() => {
  if (checkInterval) {
    clearInterval(checkInterval);
  }
});
</script>


<style scoped>
.payment-method > img {
  max-width: 25px;
}

.payment-method {
  opacity: 0.6;
  cursor: pointer;
  border: 1px solid #3a434d;
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 700;
}

.payment-method:hover {
  opacity: 1;
}

.payment-method.active {
  opacity: 1;
  cursor: pointer;
  border: 1px solid var(--brend-color);
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 700;
}

li {
  list-style-type: none;
}

.qr-help > li {
  margin-bottom: 5px;
}

.list-group {
  --bs-list-group-bg: transparent;
}

.list-group > li {
  padding-left: 0;
  padding-right: 0;
  color: var(--text-color);
}

.list-group-item {
  position: relative;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid #3a434d;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>