<template>
  <footer>
    <nav class="navbar user-select-none">
      <div class="container">
        <div class="row">
          <div class="col-6 col-lg-3 col-md-6 d-flex flex-column order-1 order-md-1">
            <span class="fs-5 mb-1 pb-1 fw-bold border-bottom text-center">Youme</span>
            <p class="mb-0">«Наша миссия – сделать так, чтобы у Вас был результат на YouTube!»</p>
          </div>

          <div class="col-12 col-lg-6 d-flex flex-column order-3 order-lg-2">
            <span class="fs-5 mb-1 pb-1 fw-bold border-bottom text-center">Документы</span>
            <ul class="p-0 m-0 text-center">
              <li>
                <router-link to="/confidential" class="text-decoration-none" style="color: var(--brend-color)">
                  <span>Политика конфиденциальности</span>
                </router-link>
              </li>
              <li>
                <router-link to="/offer" class="text-decoration-none" style="color: var(--brend-color)">
                  <span>Публичная оферта</span>
                </router-link>
              </li>
              <li>© Все права защищены.</li>
            </ul>
          </div>

          <div class="col-6 col-lg-3 col-md-6 d-flex flex-column order-2 order-lg-3">
            <span class="fs-5 mb-1 pb-1 fw-bold border-bottom text-center">Наши соц. сети</span>
            <ul class="p-0 m-0 text-center">
              <li>
                <a href="https://t.me/YoumeGG" class="text-decoration-none" style="color: var(--brend-color)">
                  <span>Канал в Телеграм</span>
                </a>
              </li>
              <li>
                <a href="https://t.me/+hhg3-yuObuBkZjky" class="text-decoration-none" style="color: var(--brend-color)">
                  <span>Чат в Телеграм</span>
                </a>
              </li>
              <li>
                <span>Youtube-канал<span class="badge ms-2">Скоро</span></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </footer>
</template>

<style scoped>
li {
  list-style-type: none;
}
</style>
