<template>
  <template v-if="store.exampleKeywordsSearchResult">
    <div class="row">
      <div class="col-12 col-lg-7 col-md-6">
        <div class="screenshot-area border-end-md" style="padding-bottom: 5px;">
          <div class="d-flex justify-content-between align-items-center border-bottom mb-2" v-if="store.loading">
            <div class="skeleton rounded-4" style="width: 250px; height: 26px;"></div>
            <div class="skeleton rounded-4" style="width: 100px; height: 26px;"></div>
          </div>
          <div class="d-flex justify-content-between align-items-center border-bottom mb-2" v-else>
            <span v-for="(keywordData, keyword) in store.exampleKeywordsSearchResult.keyword" :key="keyword" style="padding-left: 12px;">Обзор: <b>{{ keyword }}</b></span>
            <button class="btn btn-link text-decoration-none" @click="takeScreenshot">Скриншот</button>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 40px;" v-if="store.loading">
            <div class="skeleton" style="border-radius: 100%; width: 240px; height: 240px;"></div>
          </div>
          <div class="gauge-container d-flex flex-column align-items-center position-relative"
               v-for="(keywordData, index) in formattedKeywords"
               :key="`gauge-${index}`"
               style="top: 0"
          v-else>
            <div :id="`gauge-${index}`" :class="isSmallScreen ? 'full-width' : 'gauge-chart'"></div>
          </div>
          <div class="row justify-content-center" v-if="store.loading">
            <div class="col-10 d-flex justify-content-between">
              <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <div class="mb-0 skeleton rounded-4 mb-2" style="width: 120px; height: 24px;"></div>
                <div class="mb-0 skeleton rounded-4" style="width: 100px; height: 26px;"></div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <div class="mb-0 skeleton rounded-4 mb-2" style="width: 120px; height: 24px;"></div>
                <div class="mb-0 skeleton rounded-4" style="width: 100px; height: 26px;"></div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-for="(keywordData, index) in formattedKeywords" :key="`info-${index}`" v-else>
            <div class="col-10 d-flex justify-content-between">
              <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <h5 class="mb-0 fs-6 text-center" style="padding-bottom: 5px;">Объем показов</h5>
                <div class="text-center fs-5">{{ formatNumber(keywordData.estimated_monthly_search) }}</div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <h5 class="mb-0 fs-6 mb-2 text-center">Конкуренция</h5>
                <div class="rounded-4 badge-score" :class="getCompetitionClass(keywordData.competition)">
                  {{ getCompetitionText(keywordData.competition) }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-md-6 mt-4 mt-md-0">
        <div class="card rounded-4 mb-3">
          <div class="card-header">Фильтры</div>
          <div class="card-body">
            <div class="dropdown mb-2" :class="{ show: isDropdownOpen }">
              <button
                  class="btn btn-outline-primary dropdown-toggle rounded-4 w-100"
                  type="button"
                  @click="toggleDropdown"
                  :aria-expanded="isDropdownOpen"
                  :aria-label="selectedCompetitionText"
                  :disabled="store.loading"
              >
                {{ selectedCompetitionText }}
              </button>
              <ul
                  class="dropdown-menu w-100"
                  :class="{ show: isDropdownOpen }"
                  style="z-index: 9999999;"
                  role="menu"
              >
                <li
                    v-for="(competitionText, competitionValue) in competitionOptions"
                    :key="competitionValue"
                    class="dropdown-item"
                    role="menuitem"
                    @click="selectCompetition(competitionValue)"
                >
                  {{ competitionText }}
                </li>
              </ul>
            </div>
            <div class="dropdown mb-2" :class="{ show: isDropdownOpenScore }">
              <button
                  class="btn btn-outline-primary dropdown-toggle rounded-4 w-100"
                  type="button"
                  @click="toggleDropdownScore"
                  :aria-expanded="isDropdownOpenScore"
                  :aria-label="selectedOverallScoreText"
                  :disabled="store.loading"
              >
                {{ selectedOverallScoreText }}
              </button>
              <ul
                  class="dropdown-menu w-100"
                  :class="{ show: isDropdownOpenScore }"
                  style="z-index: 9999999;"
                  role="menu"
              >
                <li
                    v-for="(scoreText, scoreValue) in overallScoreOptions"
                    :key="scoreValue"
                    class="dropdown-item"
                    role="menuitem"
                    @click="selectOverallScore(scoreValue)"
                >
                  {{ scoreText }}
                </li>
              </ul>
            </div>
            <div class="input-group mb-2">
              <span class="input-group-text rounded-start-4" id="basic-addon1">Объем показов</span>
              <input
                  type="number"
                  class="form-control rounded-end-4"
                  placeholder="От"
                  v-model.number="searchVolumeFrom"
                  :disabled="store.loading"
              />
            </div>
            <div class="input-group">
              <span class="input-group-text rounded-start-4">Объем показов</span>
              <input
                  type="number"
                  class="form-control rounded-end-4"
                  placeholder="До"
                  v-model.number="searchVolumeTo"
                  :disabled="store.loading"
              />
            </div>
          </div>
        </div>
        <div class="card rounded-4">
          <div class="card-header">Экспорт данных</div>
          <div class="card-body">
            <div v-if="!selectedExportFormat">
              <div class="btn-group w-100">
                <button type="button" class="btn btn-outline-primary rounded-start-4 w-75" disabled>Выберите формат</button>
                <button
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split rounded-end-4 w-25"
                    @click="toggleExportDropdown"
                    :aria-expanded="isExportDropdownOpen"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" :class="{ show: isExportDropdownOpen }">
                  <li class="dropdown-item" @click="selectExportFormat('JSON')">JSON</li>
                  <li class="dropdown-item" @click="selectExportFormat('CSV')">CSV</li>
                  <li class="dropdown-item" @click="selectExportFormat('XLSX')">XLSX</li>
                </ul>
              </div>
            </div>
            <div v-else>
              <div class="btn-group w-100">
                <button type="button" class="btn btn-outline-primary rounded-start-4 w-75" @click="exportData">
                  Экспорт в {{ selectedExportFormat }}
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split rounded-end-4 w-25"
                    @click="toggleExportDropdown"
                    :aria-expanded="isExportDropdownOpen"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" :class="{ show: isExportDropdownOpen }">
                  <li class="dropdown-item" @click="selectExportFormat('JSON')">JSON</li>
                  <li class="dropdown-item" @click="selectExportFormat('CSV')">CSV</li>
                  <li class="dropdown-item" @click="selectExportFormat('XLSX')">XLSX</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import { useKeywordsStore } from '@/stores/tools/v1/keywords/useKeywordsStore';
import { formatNumber } from '@/utils/formatNumber';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import gauge from 'highcharts/modules/solid-gauge';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const isSmallScreen = ref(false);

const updateChartWidth = () => {
  isSmallScreen.value = window.innerWidth <= 991;
};

onMounted(() => {
  updateChartWidth();
  window.addEventListener('resize', updateChartWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateChartWidth);
});

HighchartsMore(Highcharts);
gauge(Highcharts);

const store = useKeywordsStore();
const chartInstances = ref([]);
const isDropdownOpen = ref(false);
const isDropdownOpenScore = ref(false);
const selectedExportFormat = ref(null);
const isExportDropdownOpen = ref(false);

const competitionOptions = {
  all: 'Любая конкуренция',
  'very-low': 'Очень низкая конкуренция',
  low: 'Низкая конкуренция',
  medium: 'Средняя конкуренция',
  moderate: 'Высокая конкуренция',
  high: 'Очень высокая конкуренция',
};

const overallScoreOptions = {
  all: 'Любая общая оценка',
  'very-low': 'Очень высокая общая оценка',
  low: 'Высокая общая оценка',
  medium: 'Средняя общая оценка',
  high: 'Низкая общая оценка',
  'very-high': 'Очень низкая общая оценка',
};

const selectedCompetitionText = computed(() => competitionOptions[selectedCompetition.value] || 'Любая конкуренция');
const selectedOverallScoreText = computed(() => overallScoreOptions[selectedOverallScore.value] || 'Любая общая оценка');

const toggleExportDropdown = () => {
  isExportDropdownOpen.value = !isExportDropdownOpen.value;
};

const selectExportFormat = (format) => {
  selectedExportFormat.value = format;
  isExportDropdownOpen.value = false;
};

const toggleDropdownScore = () => {
  isDropdownOpenScore.value = !isDropdownOpenScore.value;
};

const selectOverallScore = (value) => {
  selectedOverallScore.value = value;
  isDropdownOpenScore.value = false;
};

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectCompetition = (value) => {
  selectedCompetition.value = value;
  isDropdownOpen.value = false;
};

const selectedCompetition = computed({
  get: () => store.selectedCompetition,
  set: (value) => store.selectedCompetition = value
});

const selectedOverallScore = computed({
  get: () => store.selectedOverallScore,
  set: (value) => store.selectedOverallScore = value
});

const searchVolumeFrom = computed({
  get: () => store.searchVolumeFrom,
  set: (value) => store.setSearchVolumeFrom(value)
});

const searchVolumeTo = computed({
  get: () => store.searchVolumeTo,
  set: (value) => store.setSearchVolumeTo(value)
});

const competitionLevels = [
  { threshold: 80, text: 'очень высокая', class: 'high' },
  { threshold: 60, text: 'высокая', class: 'moderate' },
  { threshold: 40, text: 'средняя', class: 'medium' },
  { threshold: 20, text: 'низкая', class: 'low' },
  { threshold: 0, text: 'очень низкая', class: 'very-low' }
];

const getLevelData = (value, levels) => levels.find(level => value >= level.threshold);

const getCompetitionText = (competition) => getLevelData(competition, competitionLevels).text;

const getCompetitionClass = (competition) => getLevelData(competition, competitionLevels).class;

const destroyCharts = () => {
  chartInstances.value.forEach(chart => chart && chart.destroy());
  chartInstances.value = [];
};

const getTotalScoreText = (competition) => {
  if (competition >= 80) { return 'очень высокая'; }
  else if (competition >= 60) { return 'высокая'; }
  else if (competition >= 40) { return 'средняя'; }
  else if (competition >= 20) { return 'низкая'; }
  else { return 'очень низкая'; }
};

const getTotalScoreClass = (competition) => {
  if (competition >= 80) {
    return 'high';
  } else if (competition >= 60) {
    return 'moderate';
  } else if (competition >= 40) {
    return 'medium';
  } else if (competition >= 20) {
    return 'low';
  } else {
    return 'very-low';
  }
}

const renderGaugeChart = (elementId, score) => {
  const container = document.getElementById(elementId);
  if (!container) {
    console.error(`Container with ID ${elementId} not found.`);
    return;
  }

  const roundedScore = Math.round(score);

  const statusText = getTotalScoreText(roundedScore);
  const scoreClass = getTotalScoreClass(roundedScore);

  let backgroundColor;
  switch (scoreClass) {
    case 'high':
      backgroundColor = '#008000'; // Зеленый для high
      break;
    case 'moderate':
      backgroundColor = '#55BF3B'; // Светло-зеленый для moderate
      break;
    case 'medium':
      backgroundColor = '#ff7a00'; // Оранжевый для medium
      break;
    case 'low':
      backgroundColor = '#ff4e4e'; // Красный для low
      break;
    case 'very-low':
      backgroundColor = '#ff0000'; // Темно-красный для very-low
      break;
    default:
      backgroundColor = '#FFFFFF'; // Цвет по умолчанию
  }

  const chart = Highcharts.chart(elementId, {
    chart: {
      type: 'gauge',
      height: '80%',
      backgroundColor: 'transparent'
    },
    title: null,
    pane: {
      startAngle: -130,
      endAngle: 130,
      background: [{
        backgroundColor: 'transparent',
        borderWidth: 0,
        outerRadius: '109%'
      }]
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPosition: 'inside',
      tickLength: 12,
      tickWidth: 2,
      tickColor: '#FFFFFF',
      tickInterval: 10,
      labels: {
        distance: 20,
        style: {
          fontSize: '12px',
          color: '#FFFFFF'
        }
      },
      plotBands: [
        { from: 0, to: 19, color: '#ff0000', thickness: 20 },     // very-low (Темно-красный)
        { from: 21, to: 39, color: '#ff4e4e', thickness: 20 },    // low (Красный)
        { from: 41, to: 59, color: '#ff7a00', thickness: 20 },    // medium (Оранжевый)
        { from: 61, to: 79, color: '#55BF3B', thickness: 20 },    // moderate (Светло-зеленый)
        { from: 81, to: 100, color: '#008000', thickness: 20 }    // high (Зеленый)
      ]
    },
    series: [{
      data: [roundedScore],
      dial: {
        radius: '70%', // Уменьшаем длину стрелки
        backgroundColor: '#FFFFFF',
        baseWidth: 16,
        baseLength: '0%',
        rearLength: '0%'
      },
      pivot: {
        radius: 8,
        backgroundColor: '#FFFFFF'
      },
      dataLabels: {
        useHTML: true,
        format: `<div style="text-align:center;">
            <div style="background-color:${backgroundColor}; color: white; width: 45px; height: 45px; border-radius: 50%; display: flex; justify-content: center; align-items: center; margin: 0 auto; font-size: 20px;">
              ${roundedScore}
            </div>
            <div style="font-size:12px;color:#CCCCCC;margin-top: 10px;">ОБЩАЯ ОЦЕНКА</div>
            <div style="font-size:18px;color:white;margin-top: 5px;font-weight:bold;">${statusText}</div>
          </div>`,
        y: 10,
        borderWidth: 0,
        style: {
          fontSize: '16px',
          color: '#CCCCCC'
        }
      }
    }],
    tooltip: { enabled: false },
    credits: { enabled: false },
    accessibility: {
      enabled: false
    }
  });

  chartInstances.value.push(chart);
};

const createGauges = async () => {
  destroyCharts();
  await nextTick();
  formattedKeywords.value.forEach((keywordData, index) => {
    renderGaugeChart(`gauge-${index}`, keywordData.overall);
  });
};

const formattedKeywords = computed(() => {
  return store.exampleKeywordsSearchResult
      ? Object.values(store.exampleKeywordsSearchResult.keyword)
      : [];
});

const takeScreenshot = () => {
  const element = document.querySelector('.screenshot-area'); // Находим элемент, который нужно заскринить
  if (element) {
    html2canvas(element, {
      backgroundColor: '#242e38'  // Устанавливаем фон в нужный цвет
    }).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');  // Преобразуем скриншот в data URL
      link.download = 'screenshot.png';  // Задаём имя для сохранённого файла
      link.click();  // Автоматически кликаем по ссылке для скачивания
    });
  } else {
    console.error('Элемент для скриншота не найден');
  }
};

const exportData = () => {
  const dataToExport = getAllKeywordsData();

  if (selectedExportFormat.value === 'JSON') {
    const jsonData = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json;charset=utf-8' });
    saveAs(blob, 'data.json');
  } else if (selectedExportFormat.value === 'CSV') {
    const csvData = convertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'data.csv');
  } else if (selectedExportFormat.value === 'XLSX') {
    exportToExcel(dataToExport);
  }
};

const getAllKeywordsData = () => {
  const result = [];
  const data = store.exampleKeywordsSearchResult;

  if (!data) return result;

  if (data.keyword) {
    Object.entries(data.keyword).forEach(([keyword, keywordData]) => {
      result.push({ keyword, ...keywordData });
    });
  }

  ['questions', 'permutations', 'related'].forEach(category => {
    if (data[category]) {
      data[category].forEach(item => {
        result.push(item);
      });
    }
  });

  return result;
};

const convertToCSV = (data) => {
  if (!data || !data.length) {
    return '';
  }
  const separator = ',';
  const keys = Object.keys(data[0]);

  return keys.join(separator) +
      '\n' +
      data
          .map(row => {
            return keys
                .map(k => {
                  let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                  cell = cell.toString().replace(/"/g, '""');
                  if (cell.search(/([",\n])/g) >= 0) {
                    cell = `"${cell}"`;
                  }
                  return cell;
                })
                .join(separator);
          })
          .join('\n');
};

const exportToExcel = (data) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, 'Data');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  saveAs(blob, 'data.xlsx');
};

onMounted(createGauges);

watch(() => store.exampleKeywordsSearchResult, createGauges);
</script>

<style scoped>
.gauge-chart {
  width: 66%;
}

.full-width {
  width: 100%;
}

.high {
  background-color: #ff0000;
  color: white;
}

.moderate {
  background-color: #ff4e4e;
  color: white;
}

.medium {
  background-color: #ff7a00;
  color: white;
}

.low {
  background-color: #008000;
  color: white;
}

.very-low {
  background-color: #005d00;
  color: white;
}

.badge-score {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  font-size: 13px;
  min-width: 100px;
}
</style>